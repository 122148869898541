import { useCallback, useEffect, useMemo, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { useSnackbar } from 'notistack'
import { useIntl } from 'react-intl'
import { getAuth, checkActionCode, confirmPasswordReset } from 'firebase/auth'
import { useForm, Controller, type SubmitHandler } from 'react-hook-form'
import CircularProgress from '@mui/material/CircularProgress'
import styled from '@mui/material/styles/styled'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import LoadingButton from '@mui/lab/LoadingButton'
import FormControl from '@mui/material/FormControl'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import { hasNumber, hasSpecialChar } from 'utils/stringUtils'
import FormHelperText from '@mui/material/FormHelperText'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'

import useRoute from 'hooks/useNavigate'
import { MIN_PASSWORD_LENGTH, Path } from '../commonConstants'
import { isMobileViewState } from 'state/layoutStates'
import PasswordResetIcon from 'assets/icons/square_success.svg'
import { LightSubHeader, MainHeader } from 'components/StyledComponents'

type ResetPasswordPageProps = {
  oobCode: string | null
}

type ResetFormData = {
  password: string
}

const Wrapper = styled(Stack)`
  background: ${({ theme }) => theme.palette.background.paper};
  border-radius: ${({ theme }) => 4 * theme.shape.borderRadius}px;
  width: 560px;
  max-width: 100vw;
`

const Header = styled(Typography)`
  font-size: 28px;
  font-weight: 500;
`

const PasswordMessage = styled(FormHelperText)`
  display: flex;
  line-height: 14px;
  gap: 8px;
`

const ResetPasswordPage: React.FC<ResetPasswordPageProps> = ({ oobCode }) => {
  const { formatMessage, locale } = useIntl()
  const { goTo } = useRoute()
  const { enqueueSnackbar } = useSnackbar()
  const [showPassword, setShowPassword] = useState(false)
  const [isCodeValid, setIsCodeValid] = useState(true)
  const [isSaving, setIsSaving] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [email, setEmail] = useState<string | null>()
  const [hasSent, setHasSent] = useState(false)
  const isMobileView = useRecoilValue(isMobileViewState)
  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm<ResetFormData>({
    mode: 'onChange',
  })

  const verifyCode = useCallback(async (): Promise<void> => {
    if (oobCode) {
      try {
        const auth = getAuth()
        const result = await checkActionCode(auth, oobCode)

        setEmail(result.data.email)
      } catch (error) {
        setIsCodeValid(false)
      } finally {
        setIsLoading(false)
      }
    }
  }, [oobCode])

  useEffect(() => {
    void verifyCode()
  }, [])

  const handleClickShowPassword = (): void => {
    setShowPassword((show) => !show)
  }

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    event.preventDefault()
  }

  const onSubmit: SubmitHandler<ResetFormData> = useCallback(
    async (data): Promise<void> => {
      if (oobCode) {
        try {
          setIsSaving(true)
          const auth = getAuth()
          auth.languageCode = locale.toLowerCase()
          await confirmPasswordReset(auth, oobCode, data.password)

          setHasSent(true)

          if (!isMobileView) {
            enqueueSnackbar(
              formatMessage({ id: 'general.text.changes_saved' }),
              {
                variant: 'success',
              },
            )
            goTo(Path.LOGIN)
          }
        } catch (error) {
          enqueueSnackbar(
            formatMessage({ id: 'general.error.unknown_error' }),
            {
              variant: 'error',
            },
          )
        } finally {
          setIsSaving(false)
        }
      }
    },
    [email, oobCode, locale, isMobileView],
  )

  const shouldDisableSaving = useMemo(
    () => !isValid || isSaving || !isCodeValid,
    [isValid, isSaving, isCodeValid],
  )

  if (isLoading) {
    return <CircularProgress />
  }

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <form onSubmit={handleSubmit(onSubmit)}>
      <Wrapper padding={7} spacing={2}>
        {!hasSent && (
          <Stack alignItems="center">
            <Header>{formatMessage({ id: 'reset_password.header' })}</Header>
          </Stack>
        )}

        {!isCodeValid && (
          <Alert severity="error">
            <AlertTitle>
              {formatMessage({ id: 'reset_password.error.invalid_link' })}
            </AlertTitle>
          </Alert>
        )}

        {isCodeValid && !hasSent && (
          <>
            <FormControl fullWidth>
              <Controller
                name="password"
                control={control}
                rules={{
                  required: true,
                  validate: (val): boolean | string => {
                    if (!val) {
                      return true
                    }

                    const errors: string[] = []
                    if (val.length < MIN_PASSWORD_LENGTH) {
                      errors.push('chars_length')
                    }

                    if (!hasNumber(val)) {
                      errors.push('missing_number')
                    }

                    if (!hasSpecialChar(val)) {
                      errors.push('missing_special_char')
                    }

                    return errors.length > 0 ? errors.join() : true
                  },
                }}
                defaultValue={''}
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.password}
                    required
                    autoFocus
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            aria-label={formatMessage({
                              id: 'general.icon_button.password_visibility_toggle',
                            })}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    label={formatMessage({
                      id: 'reset_password.label.new_password',
                    })}
                  />
                )}
              />

              <Stack spacing={1} padding={1}>
                {!isDirty && (
                  <PasswordMessage>
                    <CloseIcon sx={{ fontSize: 14 }} />
                    {formatMessage(
                      {
                        id: 'reset_password.error.password.chars_length',
                      },
                      {
                        MIN_LENGTH: MIN_PASSWORD_LENGTH,
                      },
                    )}
                  </PasswordMessage>
                )}

                {isDirty &&
                  !!errors.password &&
                  (errors.password.message?.includes('chars_length') ||
                    errors.password.type === 'required') && (
                    <PasswordMessage sx={{ color: 'error.main' }}>
                      <CloseIcon sx={{ fontSize: 14 }} />
                      {formatMessage(
                        {
                          id: 'reset_password.error.password.chars_length',
                        },
                        {
                          MIN_LENGTH: MIN_PASSWORD_LENGTH,
                        },
                      )}
                    </PasswordMessage>
                  )}

                {isDirty &&
                  !errors?.password?.message?.includes('chars_length') &&
                  errors?.password?.type !== 'required' && (
                    <PasswordMessage sx={{ color: 'success.main' }}>
                      <CheckIcon sx={{ fontSize: 14 }} />
                      {formatMessage(
                        {
                          id: 'reset_password.error.password.chars_length',
                        },
                        {
                          MIN_LENGTH: MIN_PASSWORD_LENGTH,
                        },
                      )}
                    </PasswordMessage>
                  )}

                {!isDirty && (
                  <PasswordMessage>
                    <CloseIcon sx={{ fontSize: 14 }} />
                    {formatMessage({
                      id: 'reset_password.error.password.missing_number',
                    })}
                  </PasswordMessage>
                )}

                {isDirty &&
                  !!errors.password &&
                  (errors.password.message?.includes('missing_number') ||
                    errors.password.type === 'required') && (
                    <PasswordMessage sx={{ color: 'error.main' }}>
                      <CloseIcon sx={{ fontSize: 14 }} />
                      {formatMessage({
                        id: 'reset_password.error.password.missing_number',
                      })}
                    </PasswordMessage>
                  )}

                {isDirty &&
                  !errors?.password?.message?.includes('missing_number') &&
                  errors?.password?.type !== 'required' && (
                    <PasswordMessage sx={{ color: 'success.main' }}>
                      <CheckIcon sx={{ fontSize: 14 }} />
                      {formatMessage({
                        id: 'reset_password.error.password.missing_number',
                      })}
                    </PasswordMessage>
                  )}

                {!isDirty && (
                  <PasswordMessage>
                    <CloseIcon sx={{ fontSize: 14 }} />
                    {formatMessage({
                      id: 'reset_password.error.password.missing_special_char',
                    })}
                  </PasswordMessage>
                )}

                {isDirty &&
                  !!errors.password &&
                  (errors.password.message?.includes('missing_special_char') ||
                    errors.password.type === 'required') && (
                    <PasswordMessage sx={{ color: 'error.main' }}>
                      <CloseIcon sx={{ fontSize: 12 }} />
                      {formatMessage({
                        id: 'reset_password.error.password.missing_special_char',
                      })}
                    </PasswordMessage>
                  )}

                {isDirty &&
                  !errors?.password?.message?.includes(
                    'missing_special_char',
                  ) &&
                  errors?.password?.type !== 'required' && (
                    <PasswordMessage sx={{ color: 'success.main' }}>
                      <CheckIcon sx={{ fontSize: 12 }} />
                      {formatMessage({
                        id: 'reset_password.error.password.missing_special_char',
                      })}
                    </PasswordMessage>
                  )}
              </Stack>
            </FormControl>

            <LoadingButton
              variant="contained"
              size="large"
              type="submit"
              disabled={shouldDisableSaving}
              loading={isSaving}
            >
              {formatMessage({ id: 'general.button.save' })}
            </LoadingButton>
          </>
        )}

        {hasSent && isMobileView && (
          <Stack alignItems="center" spacing={2} textAlign="center">
            <PasswordResetIcon />
            <MainHeader>
              {formatMessage({
                id: 'reset_password.header.password_has_reset',
              })}
            </MainHeader>
            <LightSubHeader>
              {formatMessage({
                id: 'reset_password.text.close_window_go_to_app',
              })}
            </LightSubHeader>
          </Stack>
        )}
      </Wrapper>
    </form>
  )
}

export default ResetPasswordPage
