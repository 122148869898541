import { useCallback, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import useSWR from 'swr'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { useForm, type SubmitHandler, Controller } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import LoadingButton from '@mui/lab/LoadingButton'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import FormHelperText from '@mui/material/FormHelperText'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import OutlinedInput from '@mui/material/OutlinedInput'
import CircularProgress from '@mui/material/CircularProgress'
import Chip from '@mui/material/Chip'
import Checkbox from '@mui/material/Checkbox'
import ListItemText from '@mui/material/ListItemText'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CottageIcon from '@mui/icons-material/Cottage'

import {
  MainHeader,
  BackButtonGrey,
  SettingWrapper,
} from 'components/StyledComponents'
import useRoute from 'hooks/useNavigate'
import { Path } from '../commonConstants'
import { portalSettingState } from 'state/portalSettingStates'
import MultiLineInput from 'components/form/MultiLineInput'
import { hasUnsavedChangesState } from 'state/formStates'
import useApi from 'hooks/useApi'

import { type ItemBasic } from 'components/item/itemTypes'
import usePortalSetting from 'hooks/usePortalSetting'
import {
  TEMPLATE_MAX_LENGTH,
  TEMPLATE_NAME_MAX_LENGTH,
} from 'components/taskTemplate/taskTemplateConstants'
import {
  type TaskTemplateFormData,
  type TaskTemplate,
} from 'components/taskTemplate/taskTemplateTypes'

const TaskTemplateEditPage: React.FC = () => {
  const { formatMessage } = useIntl()
  const { templateId } = useParams()
  const { goTo } = useRoute()
  const { sendPostRequest, sendPutRequest } = useApi()
  const { enqueueSnackbar } = useSnackbar()
  const [isSaving, setIsSaving] = useState(false)
  const portalSetting = useRecoilValue(portalSettingState)
  const setHasUnsavedChanges = useSetRecoilState(hasUnsavedChangesState)

  const [items, setItems] = useState<ItemBasic[]>([])
  const [isLoadingItems, setIsLoadingItems] = useState(false)
  const { getLocalizedContent } = usePortalSetting()
  const { data: templateData, isLoading } = useSWR<TaskTemplate>(
    portalSetting && templateId
      ? `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting.id
        }/templates/tasks/${templateId}`
      : null,
  )
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid, isDirty },
    getValues,
  } = useForm<TaskTemplateFormData>({
    mode: 'onTouched',
  })

  useEffect(() => {
    if (templateData) {
      setValue('name', templateData.name)
      setValue('content', templateData.content)
      const selectedItemIds = templateData.items
        .filter((item) => item.active)
        .map((item) => item.id)
      if (selectedItemIds.length > 0) {
        setValue('itemIds', selectedItemIds)
      }
    }
  }, [templateData])

  const getItems = async (): Promise<void> => {
    setIsLoadingItems(true)
    const response = await sendPostRequest(
      `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
        portalSetting?.id
      }/templates/tasks:listAssignableItems`,
    )
    const itemsData = await response.json()
    setItems(itemsData)
    setIsLoadingItems(false)
  }

  useEffect(() => {
    if (portalSetting) {
      void getItems()
    }
  }, [portalSetting])

  useEffect(() => {
    setHasUnsavedChanges(isDirty)
  }, [isDirty])

  const handleGoBack = (): void => {
    goTo(Path.TASK_TEMPLATES_LIST)
  }

  const shouldDisableSaving = useMemo(
    () => !isValid || isLoadingItems || isSaving,
    [isValid, isLoadingItems, isSaving, portalSetting?.type],
  )

  const onSubmit: SubmitHandler<TaskTemplateFormData> = useCallback(
    async (data): Promise<void> => {
      if (portalSetting) {
        try {
          setIsSaving(true)
          const formData = {
            itemIds: data.itemIds,
            name: data.name,
            content: data.content,
          }

          if (templateId) {
            await sendPutRequest(
              `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
                portalSetting.id
              }/templates/tasks/${templateId}`,
              formData,
            )
          } else {
            await sendPostRequest(
              `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
                portalSetting.id
              }/templates/tasks`,
              formData,
            )
          }

          enqueueSnackbar(formatMessage({ id: 'general.text.changes_saved' }), {
            variant: 'success',
          })

          goTo(Path.TASK_TEMPLATES_LIST, true)
        } catch (error) {
          console.error(error)
        } finally {
          setIsSaving(false)
          setHasUnsavedChanges(false)
        }
      }
    },
    [portalSetting, templateId],
  )

  if ((templateId && isLoading) || !portalSetting) {
    return <CircularProgress />
  }

  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
        <Stack height={'100%'} width={'100%'}>
          <Stack direction="row" width="100%" spacing={2} marginBottom={2}>
            <Stack flexGrow={1} direction="row" spacing={1}>
              <BackButtonGrey
                onClick={handleGoBack}
                size="small"
                aria-label={formatMessage({
                  id: 'general.icon_button.go_back',
                })}
              >
                <ArrowBackIcon />
              </BackButtonGrey>

              <MainHeader>
                {templateId &&
                  formatMessage({ id: 'task_template_edit.header' })}
                {!templateId &&
                  formatMessage({ id: 'task_template_add.header' })}
              </MainHeader>
            </Stack>

            {(!templateId || templateData?._operations.canEdit) && (
              <LoadingButton
                variant="contained"
                size="small"
                type="submit"
                disabled={shouldDisableSaving}
                loading={isSaving}
              >
                {formatMessage({ id: 'general.button.save' })}
              </LoadingButton>
            )}
          </Stack>

          <SettingWrapper width="100%" flexGrow={1}>
            <Stack paddingY={2} paddingX={4} spacing={2}>
              <FormControl fullWidth error={!!errors.itemIds}>
                <InputLabel id="items-label" size="small" required>
                  {formatMessage({
                    id: 'tasks_template_edit.label.items',
                  })}
                </InputLabel>
                <Controller
                  name="itemIds"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  defaultValue={[]}
                  render={({ field }) => (
                    <Select
                      {...field}
                      required
                      multiple
                      labelId="items-label"
                      label={formatMessage({
                        id: 'comment_template_edit.label.items',
                      })}
                      startAdornment={
                        <InputAdornment position="start">
                          <CottageIcon fontSize="small" color="primary" />
                        </InputAdornment>
                      }
                      input={
                        <OutlinedInput
                          label={formatMessage({
                            id: 'comment_template_edit.label.items',
                          })}
                        />
                      }
                      size="small"
                      fullWidth
                      variant="outlined"
                      renderValue={(selected) => (
                        <Box
                          sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: 0.5,
                          }}
                        >
                          {selected.map((value) => {
                            const selectedItem = items?.find(
                              (item) => item.id === value,
                            )

                            return (
                              <Chip
                                key={value}
                                size="small"
                                label={getLocalizedContent(selectedItem?.names)}
                              />
                            )
                          })}
                        </Box>
                      )}
                    >
                      {items?.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          <Checkbox
                            checked={getValues('itemIds')?.includes(item.id)}
                          />
                          <ListItemText
                            primary={getLocalizedContent(item.names)}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>

              <FormControl error={!!errors.name}>
                <Controller
                  name="name"
                  control={control}
                  rules={{
                    required: true,
                    maxLength: TEMPLATE_NAME_MAX_LENGTH,
                  }}
                  defaultValue={''}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors.name}
                      required
                      size="small"
                      label={formatMessage({
                        id: 'tasks_template_edit.label.name',
                      })}
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
                {errors.name?.type === 'maxLength' && (
                  <FormHelperText>
                    {formatMessage(
                      {
                        id: 'general.error.max_length',
                      },
                      {
                        max: TEMPLATE_NAME_MAX_LENGTH,
                      },
                    )}
                  </FormHelperText>
                )}

                {errors.name?.type === 'required' && (
                  <FormHelperText>
                    {formatMessage({
                      id: 'general.error.required',
                    })}
                  </FormHelperText>
                )}
              </FormControl>

              <FormControl error={!!errors.content}>
                <Controller
                  name="content"
                  control={control}
                  rules={{
                    required: true,
                    maxLength: TEMPLATE_MAX_LENGTH,
                  }}
                  defaultValue={''}
                  render={({ field }) => (
                    <MultiLineInput
                      {...field}
                      required
                      maxLength={TEMPLATE_MAX_LENGTH}
                      error={!!errors?.content}
                      label={formatMessage({
                        id: 'tasks_template_edit.label.description',
                      })}
                      variant="outlined"
                      fullWidth
                      rows={3}
                    />
                  )}
                />
                {errors.content?.type === 'maxLength' && (
                  <FormHelperText>
                    {formatMessage(
                      {
                        id: 'general.error.max_length',
                      },
                      {
                        max: TEMPLATE_MAX_LENGTH,
                      },
                    )}
                  </FormHelperText>
                )}

                {errors.content?.type === 'required' && (
                  <FormHelperText>
                    {formatMessage({
                      id: 'general.error.required',
                    })}
                  </FormHelperText>
                )}
              </FormControl>
            </Stack>
          </SettingWrapper>
        </Stack>
      </form>
    </>
  )
}

export default TaskTemplateEditPage
