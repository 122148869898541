import { useCallback, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { getAnalytics, logEvent } from 'firebase/analytics'
import { useForm, Controller, type SubmitHandler } from 'react-hook-form'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { mutate } from 'swr'
import dayjs from 'dayjs'
import styled from '@mui/material/styles/styled'
import Grid from '@mui/material/Grid2'
import {
  Box,
  Button,
  Stack,
  Typography,
  AccordionSummary,
  AccordionDetails,
  ToggleButton,
  TextField as MuiTextField,
  FormControl,
  FormHelperText,
  InputAdornment,
  FormControlLabel,
  Switch,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import PersonIcon from '@mui/icons-material/Person'
import PostAddIcon from '@mui/icons-material/PostAdd'
import EmailIcon from '@mui/icons-material/Email'
import PhoneIcon from '@mui/icons-material/Phone'
import PublicIcon from '@mui/icons-material/Public'

import AddressInputWithMap from 'components/form/AddressInputWithMap'
import {
  type PublicNewCaseInfo,
  type PublicItemInfo,
  type PublicCaseCreationFormData,
  type PublicCaseInputData,
  type PublicCaseInfo,
} from 'components/publicCase/publicCaseTypes'
import { isValidEmail, isValidPhoneNumber } from 'utils/stringUtils'
import FileUploader from 'components/form/FileUploader'
import useApi from 'hooks/useApi'
import MultiLineInput from 'components/form/MultiLineInput'
import {
  EMAIL_MAX_LENGTH,
  PHONE_MAX_LENGTH,
  USER_NAME_MAX_LENGTH,
} from 'commonConstants'
import {
  CASE_DESCRIPTION_MAX_LENGTH,
  CASE_ATTACHMENTS_MAX_COUNT,
  AddCaseStep,
} from 'components/case/caseConstants'
import {
  Accordion,
  FormFieldsWrapper,
  SubHeader,
  SubSubHeader,
} from 'components/StyledComponents'
import {
  isFileUploadingState,
  isPublicCaseCreatedState,
  processedFormFilesState,
} from 'state/formStates'
import { selectedLocationInfoState } from 'state/mapStates'
import DateInput from 'components/form/DateInput'
import { FILE_INPUT_ACCEPT_TYPE } from 'utils/fileConstants'
import LogoWrapper from 'components/LogoWrapper'
import usePortalSetting from 'hooks/usePortalSetting'
import { publicPortalInfoState } from 'state/portalSettingStates'
import { AnonymousIcon } from 'components/icons/Icons'
import CategorySelectorTree from 'components/category/selector/CategorySelectorTree'
import { type CategoryBasic } from 'components/category/categoryTypes'
import { sortCategories } from 'utils/categoryUtils'
import { publicCaseFilterInputsState } from 'state/publicCaseListStates'

const ItemWrapper = styled(Stack)`
  gap: ${({ theme }) => theme.spacing(2)};
  flex-wrap: wrap;
`

const ItemButton = styled(ToggleButton)(({ theme }) => ({
  width: '220px',
  height: '56px',
  '&:hover': {
    background: theme.palette.primary.contrastText,
  },
  '&.Mui-selected': {
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.main,

    '&:hover': {
      background: theme.palette.primary.main,
    },
  },
  justifyContent: 'start',
}))

const TextField = styled(MuiTextField)`
  background: ${({ theme }) => theme.palette.background.paper};
`

const DisclaimerWrapper = styled(Box)`
  background: ${({ theme }) => theme.palette.error.main};
  padding: ${({ theme }) => theme.spacing(2)};
  color: ${({ theme }) => theme.palette.error.contrastText};
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  font-size: 14px;
  white-space: pre-line;
`

const SelectBox = styled(Stack)<{ selected: string }>`
  background-color: ${({ selected, theme }) =>
    selected ? theme.palette.background.paper : theme.palette.info.light};
  padding: ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing(2)};
  cursor: pointer;
  border: ${({ selected }) => (selected ? '1.5px solid' : 'none')};
  border-color: ${({ theme }) => theme.palette.primary.main};
  box-shadow: ${({ selected }) =>
    selected ? '0px 0px 0px 3px #bbc6f1' : 'none'};
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  margin: 0px;
  display: flex;
  z-index: ${({ selected }) => (selected ? 1 : 0)};
`

const FORM_NAME = 'consumerCase'

type PublicCaseAddProps = {
  portalId: string
  cases?: PublicCaseInfo[]
}

const PublicCaseAddPageMobile: React.FC<PublicCaseAddProps> = ({
  portalId,
  cases = [],
}) => {
  const { formatMessage, locale } = useIntl()
  const [selectedCategoryName, setSelectedCategoryName] = useState<
    string | null
  >()
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null)
  const [currentStep, setCurrentStep] = useState<AddCaseStep | null>(
    AddCaseStep.ITEM,
  )
  const isFileUploading = useRecoilValue(isFileUploadingState(FORM_NAME))
  const processedFormFiles = useRecoilValue(processedFormFilesState(FORM_NAME))
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
    getValues,
    watch,
    unregister,
    register,
    trigger,
    reset,
  } = useForm<PublicCaseCreationFormData>({
    mode: 'all',
  })
  const categoryIds = watch('categoryIds')
  const anonymous = watch('anonymous')
  const shareable = watch('shareable')

  const { sendPostRequest } = useApi()
  const selectedLocationInfo = useRecoilValue(
    selectedLocationInfoState(FORM_NAME),
  )
  const {
    getLocalizedContent,
    formatPhoneNumber,
    retrieveCategoryAndParentsName,
  } = usePortalSetting()
  const [isSaving, setIsSaving] = useState(false)
  const [caseInputFields, setCaseInputFields] = useRecoilState(
    publicCaseFilterInputsState,
  )
  const setIsPublicCaseCreated = useSetRecoilState(isPublicCaseCreatedState)
  const [categories, setCategories] = useState<CategoryBasic[]>([])
  const publicPortalInfo = useRecoilValue(publicPortalInfoState)

  const getCaseInputFields = useCallback(async (): Promise<void> => {
    setValue('categoryIds', '')
    setSelectedCategoryName(null)
    const response = await sendPostRequest(
      `${
        process.env.REACT_APP_PUBLIC_API_PATH ?? ''
      }/portals/${portalId}/cases:listInputs`,
    )
    const fieldsData = (await response.json()) as PublicCaseInputData
    setCaseInputFields(fieldsData)
    setCategories(sortCategories<CategoryBasic>(fieldsData.categories))
  }, [portalId])

  useEffect(() => {
    setValue('occurred', dayjs(new Date()))
    void getCaseInputFields()

    const analytics = getAnalytics()
    logEvent(analytics, 'web_public_case_add_mobile_page_view')
  }, [])

  useEffect(() => {
    if (caseInputFields?.portalItems.length === 1) {
      setSelectedItemId(caseInputFields.portalItems[0].id)
      setCurrentStep(AddCaseStep.CATEGORY)
    }
  }, [caseInputFields])

  const selectedPortalItem = useMemo((): PublicItemInfo | null => {
    if (caseInputFields && selectedItemId) {
      const selectedItem = caseInputFields.portalItems.find(
        (item) => item.id === selectedItemId,
      )

      return selectedItem ?? null
    }

    return null
  }, [caseInputFields, selectedItemId])

  const handleSelectCaseItem = useCallback((itemId: string): void => {
    setSelectedItemId(itemId)

    setCurrentStep(AddCaseStep.CATEGORY)
  }, [])

  const handleStepChange =
    (newStep: AddCaseStep) =>
    (event: React.SyntheticEvent, isExpanded: boolean) => {
      setCurrentStep(isExpanded ? newStep : null)
    }

  const resetForm = (): void => {
    reset()
    setCurrentStep(
      caseInputFields && caseInputFields.portalItems.length > 1
        ? AddCaseStep.ITEM
        : AddCaseStep.CATEGORY,
    )
    setSelectedCategoryName(null)
  }

  const onSubmit: SubmitHandler<PublicCaseCreationFormData> = useCallback(
    async (data): Promise<void> => {
      if (portalId) {
        try {
          setIsSaving(true)
          const reporter = {
            fullName: data.reporterName ?? '',
            email: data.reporterEmail ?? '',
            phone: formatPhoneNumber(data.reporterPhone ?? ''),
          }
          const formData: PublicNewCaseInfo = {
            categoryId: null,
            location: selectedLocationInfo || null,
            itemId: selectedItemId,
            description: data.description,
            locale,
            occurred: data.occurred?.format() || new Date().toISOString(),
            shareable: data.shareable,
            anonymous: data.anonymous,
          }

          if (!data.anonymous) {
            formData.reporter = reporter
          }

          if (data.categoryIds) {
            const categoryIdsList = data.categoryIds.split('|')
            const lastCategoryId = categoryIdsList[categoryIdsList.length - 1]
            formData.categoryId = lastCategoryId
          }

          if (processedFormFiles.length) {
            formData.resources = processedFormFiles.map((processedFile) => ({
              uri: processedFile.url,
              format: processedFile.format,
              name: processedFile.file.name,
            }))
          }

          const response = await sendPostRequest(
            `${
              process.env.REACT_APP_PUBLIC_API_PATH ?? ''
            }/portals/${portalId}/cases`,
            formData,
          )
          const newCaseData = await response.json()
          await mutate(
            `${
              process.env.REACT_APP_PUBLIC_API_PATH ?? ''
            }/portals/${portalId}/cases`,
            [...cases, newCaseData],
          )

          setIsPublicCaseCreated(true)
          resetForm()
        } catch (error) {
          console.error(error)
          void getCaseInputFields()
        } finally {
          setIsSaving(false)
        }
      }
    },
    [
      selectedLocationInfo,
      locale,
      processedFormFiles,
      selectedItemId,
      portalId,
      cases,
      publicPortalInfo,
    ],
  )

  const handleClickNextStep = useCallback((newStep: AddCaseStep): void => {
    setCurrentStep(newStep)
  }, [])

  const handleCategoryChange = useCallback(
    (categoryIds: string): void => {
      if (caseInputFields) {
        setValue('categoryIds', categoryIds)
        const names = retrieveCategoryAndParentsName(
          categoryIds,
          caseInputFields.categories,
        ).join('> ')
        setSelectedCategoryName(names)
        setCurrentStep(AddCaseStep.ADDRESS)
      }
    },
    [caseInputFields, locale],
  )

  const descriptionErrorMessage = useMemo((): string | undefined => {
    if (errors.description?.type === 'maxLength') {
      return formatMessage(
        {
          id: 'case_add.step.case_information.error.max_length_description',
        },
        {
          max: CASE_DESCRIPTION_MAX_LENGTH,
        },
      )
    }

    if (errors.description?.type === 'required') {
      return formatMessage({
        id: 'case_add.step.case_information.error.required_description',
      })
    }

    return undefined
  }, [errors.description])

  useEffect(() => {
    if (anonymous) {
      unregister('reporterName', {
        keepValue: true,
      })
      unregister('reporterEmail', {
        keepValue: true,
      })
      unregister('reporterPhone', {
        keepValue: true,
      })
    } else {
      register('reporterName', {
        required: true,
        maxLength: USER_NAME_MAX_LENGTH,
      })
      register('reporterEmail', {
        maxLength: EMAIL_MAX_LENGTH,
        required: true,
        validate: (val): boolean => {
          if (!val) {
            return true
          }

          return isValidEmail(val)
        },
      })
      register('reporterPhone', {
        maxLength: PHONE_MAX_LENGTH,
        validate: (val): boolean => {
          if (!val) {
            return true
          }

          return isValidPhoneNumber(val)
        },
      })
      void trigger(['reporterName', 'reporterEmail', 'reporterPhone'])
    }
  }, [anonymous])

  const shouldDisableSaving = useMemo(
    () =>
      !isValid ||
      isFileUploading ||
      !selectedLocationInfo ||
      (!categoryIds && !!caseInputFields?.categories?.length),
    [isValid, isSaving, isFileUploading, categoryIds, caseInputFields],
  )

  const disclaimers = useMemo(
    () => getLocalizedContent(selectedPortalItem?.disclaimers),
    [selectedPortalItem],
  )

  return (
    /* eslint-disable @typescript-eslint/no-misused-promises */

    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid size={{ sm: 0, md: 2, lg: 3 }}></Grid>
        <Grid size={{ sm: 12, md: 8, lg: 6 }}>
          <Stack spacing={2} width={'100%'} padding={0.5}>
            {disclaimers && (
              <DisclaimerWrapper>{disclaimers}</DisclaimerWrapper>
            )}

            {caseInputFields && caseInputFields.portalItems.length > 1 && (
              <Accordion
                defaultExpanded={true}
                expanded={currentStep === AddCaseStep.ITEM}
                onChange={handleStepChange(AddCaseStep.ITEM)}
                disableGutters
                elevation={0}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Stack direction={'row'} spacing={1} alignItems="center">
                    <CheckCircleIcon
                      color={selectedItemId ? 'success' : 'disabled'}
                    />

                    <SubHeader>
                      {selectedPortalItem && currentStep !== AddCaseStep.ITEM
                        ? getLocalizedContent(selectedPortalItem.names)
                        : formatMessage({
                            id: 'consumer_report_add.label.choose_related_item',
                          })}
                    </SubHeader>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  <ItemWrapper direction={'row'}>
                    {caseInputFields?.portalItems.map((item) => (
                      <ItemButton
                        key={item.id}
                        size="small"
                        value={item.id}
                        selected={selectedItemId === item.id}
                        onChange={() => {
                          handleSelectCaseItem(item.id)
                        }}
                      >
                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems="center"
                          justifyContent="center"
                          width={'100%'}
                        >
                          {item.logoUrl && (
                            <LogoWrapper
                              size={40}
                              url={item.logoUrl}
                              alt={getLocalizedContent(item.names)}
                            />
                          )}
                          <Box flexGrow={1} textAlign={'left'}>
                            {getLocalizedContent(item.names)}
                          </Box>
                        </Stack>
                      </ItemButton>
                    ))}
                  </ItemWrapper>
                </AccordionDetails>
              </Accordion>
            )}

            {!!caseInputFields?.categories?.length && (
              <Accordion
                defaultExpanded={false}
                expanded={currentStep === AddCaseStep.CATEGORY}
                onChange={handleStepChange(AddCaseStep.CATEGORY)}
                disabled={!selectedItemId}
                disableGutters
                elevation={0}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Stack direction={'row'} spacing={1} alignItems="center">
                    <CheckCircleIcon
                      color={selectedCategoryName ? 'success' : 'disabled'}
                    />

                    <SubHeader>
                      {selectedCategoryName &&
                      currentStep !== AddCaseStep.CATEGORY
                        ? selectedCategoryName
                        : formatMessage({
                            id: 'consumer_report_add.label.select_category',
                          })}
                    </SubHeader>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  <CategorySelectorTree
                    selectedCategoryIds={categoryIds}
                    categories={categories}
                    isLoading={false}
                    onCategoryChange={handleCategoryChange}
                  />
                </AccordionDetails>
              </Accordion>
            )}

            <Accordion
              defaultExpanded={false}
              expanded={currentStep === AddCaseStep.ADDRESS}
              onChange={handleStepChange(AddCaseStep.ADDRESS)}
              disabled={!selectedItemId}
              disableGutters
              elevation={0}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Stack direction={'row'} spacing={1} alignItems="center">
                  <CheckCircleIcon
                    color={
                      selectedLocationInfo?.address ? 'success' : 'disabled'
                    }
                  />

                  <SubHeader>
                    {selectedLocationInfo && currentStep !== AddCaseStep.ADDRESS
                      ? selectedLocationInfo.address
                      : formatMessage({
                          id: 'consumer_report_add.label.specify_location',
                        })}
                  </SubHeader>
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <Stack spacing={2}>
                  {publicPortalInfo && (
                    <AddressInputWithMap
                      mapHeight={400}
                      selectedPosition={
                        selectedPortalItem?.location?.position ||
                        publicPortalInfo.mapConfiguration?.centre
                      }
                      center={publicPortalInfo.mapConfiguration?.centre}
                      region={publicPortalInfo.mapConfiguration?.region}
                      language={publicPortalInfo.defaultLanguage.toLowerCase()}
                      zoom={publicPortalInfo.mapConfiguration?.zoomLevel}
                      formName={FORM_NAME}
                    />
                  )}

                  <Box textAlign={'right'}>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        handleClickNextStep(AddCaseStep.CASE_INFO)
                      }}
                    >
                      {formatMessage({ id: 'general.button.next' })}
                    </Button>
                  </Box>
                </Stack>
              </AccordionDetails>
            </Accordion>

            <Accordion
              defaultExpanded={false}
              expanded={currentStep === AddCaseStep.CASE_INFO}
              onChange={handleStepChange(AddCaseStep.CASE_INFO)}
              disabled={!selectedItemId}
              disableGutters
              elevation={0}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Stack direction={'row'} spacing={1} alignItems="center">
                  <CheckCircleIcon
                    color={getValues('description') ? 'success' : 'disabled'}
                  />

                  <SubHeader>
                    {formatMessage({
                      id: 'consumer_report_add.label.report_information',
                    })}
                  </SubHeader>
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <Stack spacing={2}>
                  <FormControl error={!!errors.description}>
                    <Controller
                      name="description"
                      control={control}
                      rules={{
                        required: true,
                        maxLength: CASE_DESCRIPTION_MAX_LENGTH,
                      }}
                      defaultValue=""
                      render={({ field }) => (
                        <MultiLineInput
                          {...field}
                          required
                          error={!!errors.description}
                          maxLength={CASE_DESCRIPTION_MAX_LENGTH}
                          label={formatMessage({
                            id: 'case_add.step.case_information.label.description',
                          })}
                          variant="outlined"
                          fullWidth
                          rows={5}
                          helpMessage={descriptionErrorMessage}
                        />
                      )}
                    />
                  </FormControl>

                  <FormControl>
                    <Controller
                      name="occurred"
                      control={control}
                      defaultValue={null}
                      render={({ field }) => (
                        <DateInput
                          {...field}
                          label={formatMessage({
                            id: 'consumer_report_add.label.occurred',
                          })}
                          disableFuture={true}
                          showTime={true}
                        />
                      )}
                    />
                  </FormControl>

                  <Box>
                    <Typography variant="body2">
                      {formatMessage(
                        {
                          id: 'consumer_report_add.label.image_upload',
                        },
                        {
                          max: CASE_ATTACHMENTS_MAX_COUNT,
                        },
                      )}
                    </Typography>
                    <FileUploader
                      formName={FORM_NAME}
                      accept={[
                        FILE_INPUT_ACCEPT_TYPE.IMAGE,
                        FILE_INPUT_ACCEPT_TYPE.DOCUMENT,
                        FILE_INPUT_ACCEPT_TYPE.EMAIL,
                      ]}
                      limit={CASE_ATTACHMENTS_MAX_COUNT}
                      isPublic={true}
                    />
                  </Box>
                </Stack>

                <Box textAlign={'right'} marginTop={2}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      handleClickNextStep(
                        selectedPortalItem?.configuration.caseShareable
                          ? AddCaseStep.VISIBILITY
                          : AddCaseStep.REPORTER,
                      )
                    }}
                  >
                    {formatMessage({ id: 'general.button.next' })}
                  </Button>
                </Box>
              </AccordionDetails>
            </Accordion>

            {selectedPortalItem?.configuration.caseShareable && (
              <Accordion
                defaultExpanded={false}
                expanded={currentStep === AddCaseStep.VISIBILITY}
                onChange={handleStepChange(AddCaseStep.VISIBILITY)}
                disabled={!selectedItemId}
                disableGutters
                elevation={0}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Stack direction={'row'} spacing={1} alignItems="center">
                    <CheckCircleIcon color={'success'} />
                    <Typography variant="h6">
                      {currentStep === AddCaseStep.VISIBILITY
                        ? formatMessage({
                            id: 'consumer_report_add.label.visibility',
                          })
                        : shareable
                        ? formatMessage({
                            id: 'case_detail.label.shareable',
                          })
                        : formatMessage({
                            id: 'case_detail.label.private',
                          })}
                    </Typography>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack spacing={2}>
                    <Typography variant="body2">
                      {formatMessage({
                        id: 'consumer_report_add.description.visibility',
                      })}
                    </Typography>
                    <Stack direction="row">
                      <FormFieldsWrapper>
                        <FormControlLabel
                          disableTypography
                          control={
                            <Controller
                              name="shareable"
                              control={control}
                              defaultValue={true}
                              render={({ field }) => (
                                <Switch
                                  {...field}
                                  checked={field.value}
                                  color="primary"
                                  onChange={(e) => {
                                    field.onChange(e)
                                    setValue('shareable', e.target.checked)
                                  }}
                                />
                              )}
                            />
                          }
                          label={
                            <Stack
                              flexGrow={1}
                              direction="row"
                              alignItems="center"
                              spacing={1}
                            >
                              <PublicIcon />
                              <Stack>
                                <SubSubHeader>
                                  {formatMessage({
                                    id: 'case_detail.label.shareable',
                                  })}
                                </SubSubHeader>
                                <Typography variant="caption">
                                  {formatMessage({
                                    id: 'consumer_report_add.description.shareable',
                                  })}
                                </Typography>
                              </Stack>
                            </Stack>
                          }
                          labelPlacement="start"
                          sx={{
                            marginLeft: 0,
                          }}
                        />
                      </FormFieldsWrapper>
                    </Stack>
                  </Stack>
                </AccordionDetails>
              </Accordion>
            )}

            <Accordion
              defaultExpanded={false}
              expanded={currentStep === AddCaseStep.REPORTER}
              onChange={handleStepChange(AddCaseStep.REPORTER)}
              disabled={!selectedItemId}
              disableGutters
              elevation={0}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Stack direction={'row'} spacing={1} alignItems="center">
                  <CheckCircleIcon
                    color={
                      getValues('reporterName') ||
                      getValues('reporterEmail') ||
                      getValues('reporterPhone')
                        ? 'success'
                        : 'disabled'
                    }
                  />
                  <Typography variant="h6">
                    {getValues('reporterName') &&
                    currentStep !== AddCaseStep.REPORTER
                      ? getValues('reporterName')
                      : formatMessage({
                          id: 'consumer_report_add.label.contact_details',
                        })}
                  </Typography>
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <Stack spacing={1}>
                  <Stack flexGrow={1} spacing={2}>
                    <Typography variant="body2">
                      {formatMessage({
                        id: 'consumer_report_add.text.contact_details',
                      })}
                    </Typography>

                    {selectedPortalItem?.configuration.anonymousReporting && (
                      <Stack>
                        <Controller
                          name="anonymous"
                          control={control}
                          defaultValue={false}
                          render={({ field }) => (
                            <Stack>
                              <SelectBox
                                spacing={1}
                                direction={'row'}
                                alignItems="center"
                                selected={anonymous ? '' : 'selected'}
                                onClick={() => {
                                  setValue('anonymous', false)
                                }}
                              >
                                <PersonIcon />
                                <Stack flexGrow={1}>
                                  <SubSubHeader>
                                    {formatMessage({
                                      id: 'consumer_report_add.label.with_reporter_info',
                                    })}
                                  </SubSubHeader>
                                  <Typography variant="caption">
                                    {formatMessage({
                                      id: 'consumer_report_add.description.with_reporter_info',
                                    })}
                                  </Typography>
                                </Stack>
                              </SelectBox>
                              <SelectBox
                                spacing={1}
                                direction={'row'}
                                alignItems="center"
                                selected={anonymous ? 'selected' : ''}
                                onClick={() => {
                                  setValue('anonymous', true)
                                }}
                              >
                                <AnonymousIcon />
                                <Stack flexGrow={1}>
                                  <SubSubHeader>
                                    {formatMessage({
                                      id: 'consumer_report_add.label.send_anonymously',
                                    })}
                                  </SubSubHeader>
                                  <Typography variant="caption">
                                    {formatMessage({
                                      id: 'consumer_report_add.description.send_anonymously',
                                    })}
                                  </Typography>
                                </Stack>
                              </SelectBox>
                            </Stack>
                          )}
                        />
                      </Stack>
                    )}

                    {!anonymous && (
                      <>
                        <FormControl error={!!errors.reporterName} fullWidth>
                          <Controller
                            name="reporterName"
                            control={control}
                            defaultValue={''}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                required={true && !anonymous}
                                error={!!errors.reporterName}
                                size="small"
                                label={formatMessage({
                                  id: 'consumer_report_add.label.reporter.name',
                                })}
                                slotProps={{
                                  input: {
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <PersonIcon
                                          color="primary"
                                          fontSize="small"
                                        />
                                      </InputAdornment>
                                    ),
                                  },
                                }}
                                variant="outlined"
                                fullWidth
                              />
                            )}
                          />

                          {errors.reporterName?.type === 'maxLength' && (
                            <FormHelperText>
                              {formatMessage(
                                {
                                  id: 'general.error.max_length',
                                },
                                { max: USER_NAME_MAX_LENGTH },
                              )}
                            </FormHelperText>
                          )}
                        </FormControl>

                        <FormControl error={!!errors.reporterEmail} fullWidth>
                          <Controller
                            name="reporterEmail"
                            control={control}
                            defaultValue={''}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                required={true && !anonymous}
                                error={!!errors.reporterEmail}
                                size="small"
                                label={formatMessage({
                                  id: 'consumer_report_add.label.reporter.email',
                                })}
                                slotProps={{
                                  input: {
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <EmailIcon
                                          color="primary"
                                          fontSize="small"
                                        />
                                      </InputAdornment>
                                    ),
                                  },
                                }}
                                variant="outlined"
                                fullWidth
                              />
                            )}
                          />
                          {errors.reporterEmail?.type === 'validate' && (
                            <FormHelperText>
                              {formatMessage({
                                id: 'general.error.validate_email',
                              })}
                            </FormHelperText>
                          )}
                          {errors.reporterEmail?.type === 'maxLength' && (
                            <FormHelperText>
                              {formatMessage(
                                {
                                  id: 'general.error.max_length_email',
                                },
                                { max: EMAIL_MAX_LENGTH },
                              )}
                            </FormHelperText>
                          )}
                        </FormControl>

                        <FormControl error={!!errors.reporterPhone} fullWidth>
                          <Controller
                            name="reporterPhone"
                            control={control}
                            defaultValue={''}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                error={!!errors.reporterPhone}
                                size="small"
                                label={formatMessage({
                                  id: 'consumer_report_add.label.reporter.phone',
                                })}
                                slotProps={{
                                  input: {
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <PhoneIcon
                                          color="primary"
                                          fontSize="small"
                                        />
                                      </InputAdornment>
                                    ),
                                  },
                                }}
                                variant="outlined"
                                fullWidth
                              />
                            )}
                          />
                          {errors.reporterPhone?.type === 'maxLength' && (
                            <FormHelperText>
                              {formatMessage(
                                {
                                  id: 'general.error.max_length_phone',
                                },
                                {
                                  max: PHONE_MAX_LENGTH,
                                },
                              )}
                            </FormHelperText>
                          )}
                          {errors.reporterPhone?.type === 'validate' && (
                            <FormHelperText>
                              {formatMessage({
                                id: 'general.error.validate_phone',
                              })}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </>
                    )}
                  </Stack>
                </Stack>
              </AccordionDetails>
            </Accordion>

            <Box marginTop={2}>
              <LoadingButton
                variant="contained"
                type="submit"
                fullWidth
                loading={isSaving}
                disabled={shouldDisableSaving}
                color="error"
                startIcon={<PostAddIcon />}
              >
                {formatMessage({ id: 'public_case.button.send_report' })}
              </LoadingButton>
            </Box>
          </Stack>
        </Grid>
        <Grid size={{ sm: 0, md: 2, lg: 3 }}></Grid>
      </Grid>
    </form>
  )
}

export default PublicCaseAddPageMobile
