import { useEffect, useState, useMemo, useCallback } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import useSWR from 'swr'
import { useForm, type SubmitHandler } from 'react-hook-form'
import { useSnackbar } from 'notistack'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import CircularProgress from '@mui/material/CircularProgress'

import { MainHeader, SettingWrapper } from 'components/StyledComponents'
import {
  type RolePayload,
  type Role,
  type RoleFormFields,
} from 'components/role/roleTypes'
import { Path } from '../commonConstants'
import RoleEditForm from 'components/role/RoleEditForm'
import { hasUnsavedChangesState } from 'state/formStates'
import useRoute from 'hooks/useNavigate'
import RoleList from 'components/role/RoleList'
import { portalSettingState } from 'state/portalSettingStates'
import useApi from 'hooks/useApi'
import {
  FeatureAccess,
  RoleAdminSection,
  StatisticMetric,
} from 'components/role/roleConstants'

const RoleEditPage: React.FC = () => {
  const { formatMessage } = useIntl()
  const { selectedIndex } = useParams()
  const [searchParams] = useSearchParams()
  const index = selectedIndex ? parseInt(selectedIndex, 10) : 0
  const { goTo } = useRoute()
  const [selectedRole, setSelectedRole] = useState<Role | null>(null)
  const setHasUnsavedChanges = useSetRecoilState(hasUnsavedChangesState)
  const [isSaving, setIsSaving] = useState(false)
  const portalSetting = useRecoilValue(portalSettingState)
  const { sendPostRequest, sendPutRequest } = useApi()
  const { enqueueSnackbar } = useSnackbar()
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isDirty, isValid },
  } = useForm<RoleFormFields>({
    mode: 'onTouched',
    defaultValues: {
      adminSectionAccess: {
        rules: FeatureAccess.NONE,
        teams: FeatureAccess.NONE,
        suppliers: FeatureAccess.NONE,
      },
    },
  })

  const { data: rolesData, isLoading } = useSWR<Role[]>(
    portalSetting
      ? `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting.id
        }/roles`
      : null,
  )

  const hasUnsavedChanges = useMemo(() => isDirty, [isDirty])

  const sortedRoles = useMemo(
    () =>
      rolesData?.sort((a, b) => {
        const nameA = a.name.toUpperCase()
        const nameB = b.name.toUpperCase()
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }

        return 0
      }),
    [rolesData],
  )

  useEffect(() => {
    if (!!sortedRoles?.length && index >= 0 && index < sortedRoles.length) {
      setSelectedRole(sortedRoles[index])
    } else if (index < 0 || index >= (sortedRoles?.length ?? 0)) {
      setSelectedRole(null)
    }
  }, [index, sortedRoles])

  useEffect(() => {
    setHasUnsavedChanges(hasUnsavedChanges)
  }, [hasUnsavedChanges])

  useEffect(() => {
    if (selectedRole) {
      setValue('name', selectedRole.name)
      setValue('default', selectedRole.default)
      setValue('caseAccess', selectedRole.caseAccess)
      setValue('assignmentEligibility', selectedRole.assignmentEligibility)
      setValue('platformAccess', selectedRole.platformAccess)

      const keys = Object.keys(RoleAdminSection) as Array<
        keyof typeof RoleAdminSection
      >

      keys
        .filter((key) => key !== 'stats')
        .forEach((key) => {
          setValue(
            `adminSectionAccess.${key}` as const,
            selectedRole.adminSectionAccess[key],
          )
        })

      if (
        selectedRole.visibleStatisticMetrics?.includes(
          StatisticMetric.CASE_CATEGORY_COUNTER,
        )
      ) {
        setValue('adminSectionAccess.statsCases', FeatureAccess.READ)
      }
      if (
        selectedRole.visibleStatisticMetrics?.includes(
          StatisticMetric.TEAM_TASK_COUNTER,
        )
      ) {
        setValue('adminSectionAccess.statsTeamTasks', FeatureAccess.READ)
      }
      if (
        selectedRole.visibleStatisticMetrics?.includes(
          StatisticMetric.MEMBER_TASK_COUNTER,
        )
      ) {
        setValue('adminSectionAccess.statsMemberTasks', FeatureAccess.READ)
      }
    }
  }, [selectedRole])

  const handleRoleClick = (index: number): void => {
    goTo(`${Path.ROLES_LIST}/${index}`)
  }

  const handleCancel = (): void => {
    goTo(`${Path.ROLES_LIST}/${index > -1 ? index : 0}`)
  }

  const shouldDisableSaving = useMemo(() => !isValid, [isValid])

  const onSubmit: SubmitHandler<RoleFormFields> = useCallback(
    async (data): Promise<void> => {
      if (portalSetting) {
        try {
          setIsSaving(true)
          const isCopy = searchParams.get('isCopy')
          const { adminSectionAccess, ...restData } = data
          const {
            statsCases,
            statsTeamTasks: statsTasksPerTeam,
            statsMemberTasks: statsTasksPerMember,
            ...adminSections
          } = adminSectionAccess
          const adminSectionAccessData = {
            ...adminSections,
            stats:
              statsCases === FeatureAccess.READ ||
              statsTasksPerTeam === FeatureAccess.READ ||
              statsTasksPerMember === FeatureAccess.READ
                ? FeatureAccess.READ
                : FeatureAccess.NONE,
          }
          const visibleStatisticMetricsData = []
          if (statsCases === FeatureAccess.READ) {
            visibleStatisticMetricsData.push(
              StatisticMetric.CASE_CATEGORY_COUNTER,
            )
          }
          if (statsTasksPerTeam === FeatureAccess.READ) {
            visibleStatisticMetricsData.push(StatisticMetric.TEAM_TASK_COUNTER)
          }
          if (statsTasksPerMember === FeatureAccess.READ) {
            visibleStatisticMetricsData.push(
              StatisticMetric.MEMBER_TASK_COUNTER,
            )
          }
          const formData: RolePayload = {
            ...restData,
            adminSectionAccess: adminSectionAccessData,
            visibleStatisticMetrics: visibleStatisticMetricsData,
          }

          if (selectedRole && !isCopy) {
            await sendPutRequest(
              `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
                portalSetting.id
              }/roles/${selectedRole.id}`,
              formData,
            )
          } else {
            await sendPostRequest(
              `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
                portalSetting.id
              }/roles`,
              formData,
            )
          }

          enqueueSnackbar(
            formatMessage({
              id: 'general.text.changes_saved',
            }),
            {
              variant: 'success',
            },
          )
          handleCancel()
        } catch (error) {
          console.error(error)
        } finally {
          setIsSaving(false)
          setHasUnsavedChanges(false)
        }
      }
    },
    [portalSetting, selectedRole],
  )

  const existingNames = useMemo(
    () =>
      (sortedRoles ?? [])
        .filter((role) => role.id !== selectedRole?.id)
        .map((role) => role.name.toLowerCase()),
    [selectedRole, sortedRoles],
  )

  return (
    <form
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onSubmit={handleSubmit(onSubmit)}
      style={{
        height: '100%',
        width: '100%',
      }}
    >
      <Stack height="100%" width="100%" spacing={2} overflow="auto">
        <Stack direction="row" width="100%" spacing={2}>
          <Box flexGrow={1}>
            <MainHeader>
              {formatMessage({ id: 'member_role_list.header' })}
            </MainHeader>
          </Box>

          <Button variant="outlined" size="small" onClick={handleCancel}>
            {formatMessage({ id: 'general.button.cancel' })}
          </Button>

          <LoadingButton
            loading={isSaving}
            disabled={shouldDisableSaving}
            variant="contained"
            size="small"
            type="submit"
            color="secondary"
          >
            {formatMessage({ id: 'general.button.save' })}
          </LoadingButton>
        </Stack>

        <Stack flexGrow={1} overflow="hidden">
          {isLoading && <CircularProgress />}

          <SettingWrapper width="100%" flexGrow={1}>
            <Stack
              width={'100%'}
              direction={'row'}
              flexGrow={1}
              overflow="hidden"
            >
              <RoleList
                roles={sortedRoles ?? []}
                selectedRole={selectedRole}
                handleRoleClick={handleRoleClick}
              />

              <Stack
                width={'100%'}
                direction={'row'}
                height="100%"
                overflow="hidden"
              >
                <RoleEditForm
                  isDefault={selectedRole?.default || sortedRoles?.length === 0}
                  role={selectedRole}
                  control={control}
                  errors={errors}
                  setValue={setValue}
                  existingNames={existingNames}
                />
              </Stack>
            </Stack>
          </SettingWrapper>
        </Stack>
      </Stack>
    </form>
  )
}

export default RoleEditPage
