import { useCallback, useState, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useSnackbar } from 'notistack'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import useSWR from 'swr'
import { useForm, type SubmitHandler, Controller } from 'react-hook-form'
import styled from '@mui/material/styles/styled'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import IconButton from '@mui/material/IconButton'
import LoadingButton from '@mui/lab/LoadingButton'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import PersonIcon from '@mui/icons-material/Person'
import EditIcon from '@mui/icons-material/Edit'
import CloseIcon from '@mui/icons-material/Close'

import {
  BackButtonGrey,
  LightSubHeader,
  MainHeader,
  SettingWrapper,
} from 'components/StyledComponents'
import useRoute from 'hooks/useNavigate'
import { hasUnsavedChangesState } from 'state/formStates'
import { portalSettingState } from 'state/portalSettingStates'
import { type Customer } from 'types'
import {
  CUSTOMER_PROFILE_FORM_FIELDS,
  CustomerProfileInputName,
  CustomerProfileInputType,
  Path,
  USER_NAME_MAX_LENGTH,
} from '../commonConstants'
import useApi from 'hooks/useApi'
import usePortalSetting from 'hooks/usePortalSetting'
import { type ItemBasic } from 'components/item/itemTypes'

type CustomerFormData = {
  [K in CustomerProfileInputName[number]]?: string
}

export const ContentWrapper = styled(Stack)`
  background: ${({ theme }) => theme.palette.info.light};
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  width: 100%;
  gap: ${({ theme }) => theme.spacing(1)};
  padding: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(2)};
  margin-top: ${({ theme }) => theme.spacing(1)};
  align-items: center;
`

const CustomerEditPage: React.FC = () => {
  const { formatMessage } = useIntl()
  const { goTo } = useRoute()
  const { customerId } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const [isSaving, setIsSaving] = useState(false)
  const setHasUnsavedChanges = useSetRecoilState(hasUnsavedChangesState)
  const portalSetting = useRecoilValue(portalSettingState)
  const { sendPostRequest, sendPutRequest } = useApi()
  const { getLocalizedContent } = usePortalSetting()
  const [itemsData, setItemsData] = useState<ItemBasic[]>([])
  const [selectedItemIds, setSelectedItemIds] = useState<string[]>([])
  const [isItemsDialogOpen, setIsItemsDialogOpen] = useState(false)

  const { data: customerData, mutate: mutateCustomerData } = useSWR<Customer>(
    portalSetting && customerId
      ? `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting.id
        }/customers/${customerId}`
      : null,
  )
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid, isDirty },
  } = useForm<CustomerFormData>({
    mode: 'onTouched',
  })

  const getItems = useCallback(async (): Promise<void> => {
    try {
      if (portalSetting) {
        const response = await sendPostRequest(
          `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
            portalSetting.id
          }/customers:listAssignableItems`,
        )
        const data = await response.json()
        setItemsData(data)
      }
    } catch (error) {
      console.error(error)
    }
  }, [portalSetting])

  useEffect(() => {
    if (customerData && portalSetting?.portalInfo) {
      void getItems()

      const selectedItemIds = customerData.items
        .filter((item) => item.active)
        .map((item) => item.id)
      if (selectedItemIds.length > 0) {
        setSelectedItemIds(selectedItemIds)
      }

      const fieldNames = Object.keys(portalSetting?.portalInfo?.customerProfile)
      fieldNames.forEach((fieldName) => {
        if (
          fieldName === CustomerProfileInputName.NAME &&
          portalSetting?.portalInfo?.customerProfile.name !==
            CustomerProfileInputType.HIDDEN
        ) {
          setValue('firstName', customerData.firstName)
          setValue('lastName', customerData.lastName)
        } else if (
          portalSetting?.portalInfo?.customerProfile?.[
            fieldName as CustomerProfileInputName
          ] !== CustomerProfileInputType.HIDDEN
        ) {
          const value = customerData[fieldName as keyof Customer]
          console.log(fieldName, value)
          if (typeof value === 'string' || value === undefined) {
            setValue(fieldName, value)
          }
        }
      })
    }
  }, [customerData, portalSetting])

  const onSubmit: SubmitHandler<CustomerFormData> = useCallback(
    async (data): Promise<void> => {
      if (portalSetting && customerId) {
        try {
          setIsSaving(true)
          const response = await sendPutRequest(
            `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
              portalSetting.id
            }/customers/${customerId}`,
            data,
          )

          const updatedData = await response.json()
          await mutateCustomerData(updatedData)

          enqueueSnackbar(formatMessage({ id: 'general.text.changes_saved' }), {
            variant: 'success',
          })

          goTo(Path.CUSTOMERS_LIST, true)
        } catch (error) {
          console.error(error)
        } finally {
          setIsSaving(false)
          setHasUnsavedChanges(false)
        }
      }
    },
    [portalSetting, customerId],
  )

  const handleGoBack = (): void => {
    goTo(Path.CUSTOMERS_LIST)
  }

  const hasUnsavedChanges = isDirty

  const shouldDisableSaving = useMemo(
    () => !isValid || isSaving,
    [isValid, isSaving],
  )

  useEffect(() => {
    setHasUnsavedChanges(hasUnsavedChanges)
  }, [hasUnsavedChanges])

  const renderCustomerProfileFormFields = useCallback(() => {
    if (portalSetting) {
      return CUSTOMER_PROFILE_FORM_FIELDS.map(
        ({ name, label, maxLength, validate, icon }) => {
          if (
            portalSetting.portalInfo?.customerProfile[name] ===
            CustomerProfileInputType.HIDDEN
          ) {
            return null
          }

          const IconComponent = icon

          return (
            <FormControl key={name} error={!!errors[name]} fullWidth>
              <Controller
                name={name}
                control={control}
                rules={{
                  required:
                    portalSetting.portalInfo?.customerProfile[name] ===
                    CustomerProfileInputType.REQUIRED,
                  maxLength,
                  validate: (val): boolean => {
                    if (validate) {
                      return validate(val)
                    }

                    return true
                  },
                }}
                defaultValue={''}
                render={({ field }) => (
                  <TextField
                    {...field}
                    required={
                      portalSetting?.portalInfo?.customerProfile[name] ===
                      CustomerProfileInputType.REQUIRED
                    }
                    error={!!errors[name]}
                    size="small"
                    label={formatMessage({
                      id: label,
                    })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconComponent fontSize="small" color="primary" />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    fullWidth
                  />
                )}
              />

              {name === CustomerProfileInputName.PHONE &&
                errors?.[name]?.type === 'validate' && (
                  <FormHelperText>
                    {formatMessage({
                      id: 'general.error.validate_phone',
                    })}
                  </FormHelperText>
                )}

              {name === CustomerProfileInputName.EMAIL &&
                errors?.[name]?.type === 'validate' && (
                  <FormHelperText>
                    {formatMessage({
                      id: 'general.error.validate_email',
                    })}
                  </FormHelperText>
                )}

              {errors?.[name]?.type === 'required' && (
                <FormHelperText>
                  {formatMessage({
                    id: 'general.error.required',
                  })}
                </FormHelperText>
              )}

              {errors?.[name]?.type === 'maxLength' && (
                <FormHelperText>
                  {formatMessage(
                    {
                      id: 'general.error.max_length',
                    },
                    {
                      max: maxLength,
                    },
                  )}
                </FormHelperText>
              )}
            </FormControl>
          )
        },
      )
    }

    return null
  }, [portalSetting, errors])

  const handleEditItems = (): void => {
    setIsItemsDialogOpen(true)
  }

  const handleCloseItemsDialog = (): void => {
    setIsItemsDialogOpen(false)
  }

  const handleItemsChange = (checked: boolean, itemId: string): void => {
    if (checked) {
      setSelectedItemIds((prev) => [...prev, itemId])
    } else {
      setSelectedItemIds((prev) => prev.filter((id) => id !== itemId))
    }
  }

  const handleSaveItems = useCallback(async (): Promise<void> => {
    if (portalSetting && customerId && customerData) {
      try {
        setIsSaving(true)

        const response = await sendPutRequest(
          `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
            portalSetting.id
          }/customers/${customerId}/items`,
          {
            identifiers: selectedItemIds,
          },
        )

        const itemsData = await response.json()
        await mutateCustomerData({
          ...customerData,
          items: itemsData as ItemBasic[],
        })

        enqueueSnackbar(
          formatMessage({
            id: 'general.text.changes_saved',
          }),
          {
            variant: 'success',
          },
        )
        handleCloseItemsDialog()
      } catch (error) {
        console.error(error)
      } finally {
        setIsSaving(false)
      }
    }
  }, [portalSetting, customerId, selectedItemIds, customerData])

  return (
    <>
      <Stack height={'100%'} width={'100%'}>
        <Stack direction="row" width="100%" spacing={2} marginBottom={2}>
          <BackButtonGrey
            onClick={handleGoBack}
            size="small"
            aria-label={formatMessage({
              id: 'general.icon_button.go_back',
            })}
          >
            <ArrowBackIcon />
          </BackButtonGrey>

          <MainHeader>
            {formatMessage({
              id: customerId ? 'customer_edit.header' : 'customer_add.header',
            })}
          </MainHeader>
        </Stack>

        <SettingWrapper width="100%" flexGrow={1}>
          {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack paddingY={2} paddingX={4} spacing={2}>
              <Stack direction="row" width="100%">
                <Stack flexGrow={1}>
                  <LightSubHeader>
                    {formatMessage({
                      id: 'customer_edit.subheader.personal_information',
                    })}
                  </LightSubHeader>
                </Stack>

                <LoadingButton
                  variant="contained"
                  size="small"
                  type="submit"
                  disabled={shouldDisableSaving}
                  loading={isSaving}
                  color="secondary"
                >
                  {formatMessage({ id: 'general.button.save' })}
                </LoadingButton>
              </Stack>

              <Stack spacing={2} flexGrow={1}>
                {portalSetting?.portalInfo?.customerProfile.name !==
                  CustomerProfileInputType.HIDDEN && (
                  <Stack direction="row" width="100%" spacing={2}>
                    <FormControl error={!!errors.firstName} fullWidth>
                      <Controller
                        name="firstName"
                        control={control}
                        rules={{
                          required:
                            portalSetting?.portalInfo?.customerProfile.name ===
                            CustomerProfileInputType.REQUIRED,
                          maxLength: USER_NAME_MAX_LENGTH,
                        }}
                        defaultValue={''}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            error={!!errors.firstName}
                            required={
                              portalSetting?.portalInfo?.customerProfile
                                .name === CustomerProfileInputType.REQUIRED
                            }
                            size="small"
                            label={formatMessage({
                              id: 'customer_edit.label.first_name',
                            })}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <PersonIcon
                                    fontSize="small"
                                    color="primary"
                                  />
                                </InputAdornment>
                              ),
                            }}
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />
                    </FormControl>

                    <FormControl error={!!errors.lastName} fullWidth>
                      <Controller
                        name="lastName"
                        control={control}
                        rules={{
                          required:
                            portalSetting?.portalInfo?.customerProfile.name ===
                            CustomerProfileInputType.REQUIRED,
                          maxLength: USER_NAME_MAX_LENGTH,
                        }}
                        defaultValue={''}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            error={!!errors.lastName}
                            required={
                              portalSetting?.portalInfo?.customerProfile
                                .name === CustomerProfileInputType.REQUIRED
                            }
                            size="small"
                            label={formatMessage({
                              id: 'customer_edit.label.last_name',
                            })}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />
                    </FormControl>
                  </Stack>
                )}

                {renderCustomerProfileFormFields()}
              </Stack>
            </Stack>
          </form>

          <Divider />

          <Stack paddingY={2} paddingX={4} spacing={2}>
            <LightSubHeader>
              {formatMessage({
                id: 'member_edit.subheader.access_preferences',
              })}
            </LightSubHeader>

            <ContentWrapper direction="row" data-testid="items-list">
              <Box flexGrow={1}>
                <Typography variant="body1">
                  {formatMessage({
                    id: 'member_edit.label.items',
                  })}
                </Typography>
              </Box>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="body2">
                  {(customerData?.items ?? [])
                    .map((item) => getLocalizedContent(item.names))
                    .join(', ')}
                </Typography>
                <IconButton
                  size="small"
                  onClick={handleEditItems}
                  aria-label={formatMessage({
                    id: 'general.icon_button.edit',
                  })}
                  color="primary"
                >
                  <EditIcon />
                </IconButton>
              </Stack>
            </ContentWrapper>
          </Stack>
        </SettingWrapper>
      </Stack>

      <Dialog open={isItemsDialogOpen} onClose={handleCloseItemsDialog}>
        <DialogTitle>
          {formatMessage({
            id: 'member_edit.dialog.items.title',
          })}
        </DialogTitle>
        <IconButton
          onClick={handleCloseItemsDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          aria-label={formatMessage({
            id: ' general.button.close',
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Stack width="500px" spacing={1}>
            {itemsData?.map((itemData) => (
              <FormControlLabel
                key={itemData.id}
                control={
                  <Checkbox
                    checked={selectedItemIds.includes(itemData.id)}
                    onChange={(event, checked) => {
                      handleItemsChange(checked, itemData.id)
                    }}
                  />
                }
                label={getLocalizedContent(itemData.names)}
              />
            ))}
          </Stack>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            fullWidth
            onClick={(): void => {
              void handleSaveItems()
            }}
            variant="contained"
            loading={isSaving}
          >
            {formatMessage({ id: 'general.button.save' })}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default CustomerEditPage
