import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { useForm, Controller, type SubmitHandler } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'
import { nanoid } from 'nanoid'
import { useSnackbar } from 'notistack'
import LoadingButton from '@mui/lab/LoadingButton'
import styled from '@mui/material/styles/styled'
import {
  Box,
  Button,
  Stack,
  Typography,
  FormControl,
  TextField,
  InputAdornment,
  FormHelperText,
  Divider,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import WebsiteIcon from '@mui/icons-material/Language'
import TopicIcon from '@mui/icons-material/Topic'
import EditIcon from '@mui/icons-material/Edit'
import EmailIcon from '@mui/icons-material/Email'

import {
  FormFieldsWrapper,
  MainHeader,
  Switch,
  BackButtonGrey,
  SettingWrapper,
  LightSubHeader,
} from 'components/StyledComponents'
import useRoute from 'hooks/useNavigate'
import { type LocalizedString, type Locale } from 'types'
import {
  type ItemBasicPayload,
  type Item,
  type ItemFormFields,
  type ContactFormField,
  type ContactDetail,
  type ContactDetailFormData,
} from 'components/item/itemTypes'
import { EMAIL_MAX_LENGTH, Path } from '../commonConstants'
import {
  ITEM_DESCRIPTION_MAX_LENGTH,
  ITEM_NAME_MAX_LENGTH,
  ITEM_WEBSITE_MAX_LENGTH,
  ITEM_REPORT_DISCLAIMER_MAX_LENGTH,
  ItemNewsSource,
  ContactDetailType,
  BusinessSegment,
  BUSINESS_SEGMENT_LABELS,
  BUSINESS_SEGMENT_ICONS,
} from 'components/item/itemConstants'
import {
  portalSettingState,
  sortedSupportLanguagesSelector,
} from 'state/portalSettingStates'
import MultiLineInput from 'components/form/MultiLineInput'
import {
  isFileUploadingState,
  hasUnsavedChangesState,
  processedFormFilesState,
} from 'state/formStates'
import TranslationDialog from 'components/helpers/TranslationDialog'
import useApi from 'hooks/useApi'
import ImageUploader from 'components/form/ImageUploader'
import {
  convertDataToLocalizedString,
  convertLocalizedStringToData,
} from 'utils/stringUtils'
import ContactListForm from 'components/item/ContactListForm'

const FORM_NAME = 'item'

const MAX_IMAGE_WIDTH_IN_PX_FOR_UPLOAD = 500
const MAX_IMAGE_HEIGHT_IN_PX_FOR_UPLOAD = 500

const ItemNameWrapper = styled(Box)`
  margin-top: 0;
`

const SegmentWrapper = styled(Stack)`
  svg {
    height: 20px;
    width: 20px;
  }
`

const ItemEditPage: React.FC = () => {
  const { formatMessage } = useIntl()
  const { itemId } = useParams()
  const { goTo } = useRoute()
  const supportLanguages = useRecoilValue(sortedSupportLanguagesSelector)
  const isFileUploading = useRecoilValue(isFileUploadingState(FORM_NAME))
  const processedFormFiles = useRecoilValue(processedFormFilesState(FORM_NAME))
  const { sendPutRequest } = useApi()
  const { enqueueSnackbar } = useSnackbar()
  const [isSaving, setIsSaving] = useState(false)
  const portalSetting = useRecoilValue(portalSettingState)
  const [isTranslationDialogOpen, setIsTranslationDialogOpen] = useState(false)
  const [translationDialogTitle, setTranslationDialogTitle] = useState('')
  const [translationInputRows, setTranslationInputRows] = useState(3)
  const setHasUnsavedChanges = useSetRecoilState(hasUnsavedChangesState)

  const translationContext = useRef<
    | 'names'
    | 'descriptions'
    | 'newsFeedUrls'
    | 'eventsFeedUrls'
    | 'disclaimers'
    | null
  >(null)
  const [maxTranslationLength, setMaxTranslationLength] = useState(
    ITEM_DESCRIPTION_MAX_LENGTH,
  )
  const { data: itemData } = useSWR<Item>(
    portalSetting && itemId
      ? `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting.id
        }/items/${itemId}/basic`
      : null,
  )
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid, isDirty, dirtyFields },
    getValues,
    trigger,
    watch,
    clearErrors,
  } = useForm<ItemFormFields>({
    mode: 'onTouched',
    defaultValues: {
      contacts: [],
      newsSource: ItemNewsSource.NEWS_POSTS,
    },
  })

  const publicCases = watch('publicCases')
  const names = watch('names')
  const descriptions = watch('descriptions')
  const disclaimers = watch('disclaimers')
  const contacts = watch('contacts')
  const logoUrl = watch('logoUrl')

  useEffect(() => {
    if (itemData) {
      setValue('names', convertLocalizedStringToData(itemData.names))
      setValue('website', itemData.website)
      setValue(
        'descriptions',
        convertLocalizedStringToData(itemData.descriptions),
      )

      if (itemData.contacts.length > 0) {
        setValue(
          'contacts',
          itemData.contacts.map((contact) => {
            const details: ContactDetailFormData[] = []

            if (contact.addresses?.length) {
              details.push({
                type: ContactDetailType.ADDRESS,
                texts: convertLocalizedStringToData(contact.addresses),
                position: contact.position || null,
              })
            }

            contact.details.forEach((detail) => {
              details.push({
                type: detail.type,
                texts: convertLocalizedStringToData(detail.texts),
              })
            })

            return {
              id: nanoid(6),
              names: convertLocalizedStringToData(contact.names),
              descriptions: convertLocalizedStringToData(contact.descriptions),
              details,
            }
          }),
        )
      }

      setValue(
        'disclaimers',
        convertLocalizedStringToData(itemData.disclaimers),
      )

      if (itemData.businessSegment) {
        setValue('businessSegment', itemData.businessSegment)
      }

      setValue('anonymousReporting', itemData.anonymousReporting)

      if (itemData.logoUrl) {
        setValue('logoUrl', itemData.logoUrl)
      }
      void trigger()
    }
  }, [itemData])

  const hasUnsavedChanges = isDirty

  useEffect(() => {
    setHasUnsavedChanges(hasUnsavedChanges)
  }, [hasUnsavedChanges])

  useEffect(() => {
    if (!publicCases) {
      clearErrors('publicCaseRetentionDays')
    }
  }, [publicCases])

  useEffect(() => {
    setValue('logoUrl', processedFormFiles?.[0]?.url)
  }, [processedFormFiles])

  const onSubmit: SubmitHandler<ItemFormFields> = useCallback(
    async (data): Promise<void> => {
      if (!portalSetting || !itemData) {
        return
      }

      try {
        setIsSaving(true)
        const formData: ItemBasicPayload = {
          website: data.website,
          names: convertDataToLocalizedString(data.names),
          descriptions: convertDataToLocalizedString(data.descriptions),
          disclaimers: convertDataToLocalizedString(data.disclaimers),
          contacts: [],
          logoUrl: data.logoUrl,
          anonymousReporting: data.anonymousReporting,
        }

        if (data.businessSegment) {
          formData.businessSegment = data.businessSegment
        }

        if (data.contacts.length) {
          formData.contacts = data.contacts.map((contact) => {
            const details: ContactDetail[] = []
            let addresses: LocalizedString[] = []
            let position = null

            contact.details.forEach((detail) => {
              if (detail.type === ContactDetailType.ADDRESS) {
                addresses = convertDataToLocalizedString(detail.texts)
                position = detail.position
              } else {
                details.push({
                  type: detail.type,
                  texts: convertDataToLocalizedString(detail.texts),
                })
              }
            })

            return {
              names: convertDataToLocalizedString(contact.names),
              descriptions: convertDataToLocalizedString(contact.descriptions),
              addresses,
              details,
              position,
            }
          })
        }

        await sendPutRequest(
          `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
            portalSetting?.id
          }/items/${itemData.id}/basic`,
          formData,
        )

        setHasUnsavedChanges(false)
        enqueueSnackbar(formatMessage({ id: 'general.text.changes_saved' }), {
          variant: 'success',
        })

        goTo(Path.ITEMS_LIST, true)
      } catch (error) {
        console.error(error)
      } finally {
        setIsSaving(false)
      }
    },
    [itemData],
  )

  const handleGoBack = (): void => {
    goTo(Path.ITEMS_LIST)
  }

  const handleOpenNameTranslationDialog = (): void => {
    setIsTranslationDialogOpen(true)
    translationContext.current = 'names'
    setMaxTranslationLength(ITEM_NAME_MAX_LENGTH)
    setTranslationDialogTitle(
      formatMessage({
        id: 'portal_item_edit.button.manage_localization',
      }),
    )
    setTranslationInputRows(1)
  }

  const handleOpenDescriptionTranslationDialog = (): void => {
    setIsTranslationDialogOpen(true)
    translationContext.current = 'descriptions'
    setMaxTranslationLength(ITEM_DESCRIPTION_MAX_LENGTH)
    setTranslationDialogTitle(
      formatMessage({
        id: 'portal_item_edit.button.manage_localization',
      }),
    )
    setTranslationInputRows(3)
  }

  const handleOpenDisclaimerTranslationDialog = (): void => {
    setIsTranslationDialogOpen(true)
    translationContext.current = 'disclaimers'
    setMaxTranslationLength(ITEM_DESCRIPTION_MAX_LENGTH)
    setTranslationDialogTitle(
      formatMessage({
        id: 'portal_item_edit.button.manage_localization',
      }),
    )
    setTranslationInputRows(3)
  }

  const handleSaveTranslation = (data: Record<Locale, string>): void => {
    if (translationContext.current) {
      setValue(translationContext.current, data, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      })
      void trigger(translationContext.current)
    }
    setIsTranslationDialogOpen(false)
  }

  const handleCloseTranslationDialog = (): void => {
    setIsTranslationDialogOpen(false)
  }

  const getTranslationDefaultLanguageValue = useCallback((): Record<
    string,
    string
  > => {
    if (translationContext.current) {
      return getValues(translationContext.current)
    }

    return {}
  }, [descriptions, disclaimers, names, translationContext.current])

  const validateTranslations = (
    name: 'names' | 'descriptions' | 'disclaimers',
  ): null | string => {
    if (!portalSetting) {
      return null
    }

    const data = getValues(name)
    let missingTranslations: Locale[] = [...portalSetting.supportedLanguages]

    if (data) {
      const keys = Object.keys(data)
      keys.forEach((key) => {
        if (data[key]) {
          missingTranslations = missingTranslations.filter(
            (item) => item !== key,
          )
        }
      })

      if (missingTranslations.length) {
        return missingTranslations.join(', ')
      }
    }

    return null
  }

  const missingNamesTranslation = useMemo(
    () => !!dirtyFields.names && !!validateTranslations('names'),
    [names],
  )

  const shouldDisableSaving = useMemo(
    () =>
      !isValid ||
      isSaving ||
      isFileUploading ||
      missingNamesTranslation ||
      !logoUrl,
    [isValid, isSaving, missingNamesTranslation, isFileUploading, logoUrl],
  )

  const handleSaveContact = (
    data: ContactFormField,
    contactId: string,
  ): void => {
    if (contactId === '-1') {
      setValue('contacts', [...contacts, data])
    } else {
      const newContacts = [...contacts]
      const contactIndex = newContacts.findIndex(
        (contact) => contact.id === contactId,
      )
      newContacts[contactIndex] = data

      setValue('contacts', newContacts, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      })
    }
  }

  const handleDeleteContact = (contactId: string): void => {
    const newContacts = [...getValues('contacts')]
    const contactIndex = newContacts.findIndex(
      (contact) => contact.id === contactId,
    )
    newContacts.splice(contactIndex, 1)
    setValue('contacts', newContacts, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    })
  }

  const handleMoveContact = (dragIndex: number, hoverIndex: number): void => {
    const newContacts = [...getValues('contacts')]
    const dragItem = newContacts[dragIndex]
    newContacts.splice(dragIndex, 1)
    newContacts.splice(hoverIndex, 0, dragItem)
    setValue('contacts', newContacts, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    })
  }

  if (!portalSetting) {
    return null
  }

  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
        <Stack height={'100%'} width={'100%'}>
          <Stack direction="row" width="100%" spacing={2} marginBottom={2}>
            <Stack flexGrow={1} direction="row" spacing={1}>
              <BackButtonGrey
                onClick={handleGoBack}
                size="small"
                aria-label={formatMessage({
                  id: 'general.icon_button.go_back',
                })}
              >
                <ArrowBackIcon />
              </BackButtonGrey>

              <MainHeader>
                {formatMessage({ id: 'portal_item_list.menu.edit_basic_info' })}
              </MainHeader>
            </Stack>

            <LoadingButton
              variant="contained"
              size="small"
              type="submit"
              disabled={shouldDisableSaving}
              loading={isSaving}
              color="secondary"
            >
              {formatMessage({ id: 'general.button.save' })}
            </LoadingButton>
          </Stack>

          <SettingWrapper width="100%" flexGrow={1}>
            <Stack paddingY={2} paddingX={4} spacing={2}>
              <LightSubHeader>
                {formatMessage({
                  id: 'portal_item_edit.step.item_info',
                })}
              </LightSubHeader>

              <Typography variant="body2">
                {formatMessage({
                  id: 'portal_item_edit.step.item_info.text.info_visible_to_users',
                })}
              </Typography>

              <Stack direction="row" spacing={2} width="100%">
                <Box width="150px" height="150px">
                  <ImageUploader
                    formName={FORM_NAME}
                    maxImageWidth={MAX_IMAGE_WIDTH_IN_PX_FOR_UPLOAD}
                    maxImageHeight={MAX_IMAGE_HEIGHT_IN_PX_FOR_UPLOAD}
                    defaultImageUrl={
                      itemData?.logoUrl || portalSetting?.logoUrl
                    }
                  />

                  {!logoUrl && isDirty && (
                    <FormHelperText error>
                      {formatMessage({
                        id: 'general.error.required',
                      })}
                    </FormHelperText>
                  )}
                </Box>

                <Stack spacing={2} flexGrow={1}>
                  <ItemNameWrapper>
                    {supportLanguages.length > 1 && (
                      <Stack direction="row" justifyContent="end">
                        <Button
                          size="small"
                          startIcon={<EditIcon />}
                          onClick={handleOpenNameTranslationDialog}
                        >
                          {formatMessage({
                            id: 'portal_item_edit.button.manage_localization',
                          })}
                        </Button>
                      </Stack>
                    )}

                    <Controller
                      name={`names.${portalSetting.defaultLanguage}`}
                      control={control}
                      rules={{
                        maxLength: ITEM_NAME_MAX_LENGTH,
                        required: true,
                      }}
                      defaultValue={''}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          required
                          error={!!errors.names}
                          size="small"
                          label={formatMessage({
                            id: 'portal_item_edit.step.item_info.label.name',
                          })}
                          slotProps={{
                            input: {
                              startAdornment: (
                                <InputAdornment position="start">
                                  <TopicIcon fontSize="small" color="primary" />
                                </InputAdornment>
                              ),
                            },
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                    {errors.names?.[portalSetting.defaultLanguage]?.type ===
                      'maxLength' && (
                      <FormHelperText error>
                        {formatMessage(
                          {
                            id: 'portal_item_edit.step.item_info.error.max_length_name',
                          },
                          {
                            max: ITEM_NAME_MAX_LENGTH,
                          },
                        )}
                      </FormHelperText>
                    )}

                    {!!dirtyFields.names && !!validateTranslations('names') && (
                      <FormHelperText error>
                        {formatMessage(
                          {
                            id: 'portal_item_edit.error.missing_translations',
                          },
                          {
                            missingTranslations: validateTranslations('names'),
                          },
                        )}
                      </FormHelperText>
                    )}
                  </ItemNameWrapper>

                  {itemData?.locationDefined && (
                    <FormControl fullWidth>
                      <InputLabel id="item-segment-label" size="small" shrink>
                        {formatMessage({
                          id: 'portal_item_edit.label.segment',
                        })}
                      </InputLabel>
                      <Controller
                        name="businessSegment"
                        control={control}
                        defaultValue={null}
                        render={({ field }) => (
                          <Select
                            {...field}
                            labelId="item-segment-label"
                            label={formatMessage({
                              id: 'portal_item_edit.label.segment',
                            })}
                            size="small"
                            fullWidth
                            variant="outlined"
                            displayEmpty
                            renderValue={(selected) => {
                              if (!selected) {
                                return null
                              }

                              const IconComponent =
                                BUSINESS_SEGMENT_ICONS[selected]
                              return (
                                <SegmentWrapper
                                  direction="row"
                                  spacing={1}
                                  alignItems="center"
                                >
                                  <IconComponent />
                                  <Box>
                                    {formatMessage({
                                      id: BUSINESS_SEGMENT_LABELS[selected],
                                    })}
                                  </Box>
                                </SegmentWrapper>
                              )
                            }}
                          >
                            <MenuItem value="">
                              <Box height={20}> </Box>
                            </MenuItem>
                            {Object.keys(BusinessSegment).map((segment) => {
                              const IconComponent =
                                BUSINESS_SEGMENT_ICONS[
                                  segment as BusinessSegment
                                ]

                              return (
                                <MenuItem key={segment} value={segment}>
                                  <ListItemIcon>
                                    <SegmentWrapper>
                                      <IconComponent />
                                    </SegmentWrapper>
                                  </ListItemIcon>
                                  <ListItemText>
                                    {formatMessage({
                                      id: BUSINESS_SEGMENT_LABELS[
                                        segment as BusinessSegment
                                      ],
                                    })}
                                  </ListItemText>
                                </MenuItem>
                              )
                            })}
                          </Select>
                        )}
                      />
                    </FormControl>
                  )}

                  <FormControl error={!!errors.website} fullWidth>
                    <Controller
                      name="website"
                      control={control}
                      rules={{
                        maxLength: ITEM_WEBSITE_MAX_LENGTH,
                      }}
                      defaultValue={''}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          error={!!errors.website}
                          size="small"
                          label={formatMessage({
                            id: 'portal_item_edit.step.item_info.website',
                          })}
                          slotProps={{
                            input: {
                              startAdornment: (
                                <InputAdornment position="start">
                                  <WebsiteIcon
                                    fontSize="small"
                                    color="primary"
                                  />
                                </InputAdornment>
                              ),
                            },
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                    {errors.website?.type === 'maxLength' && (
                      <FormHelperText>
                        {formatMessage(
                          {
                            id: 'portal_item_edit.step.item_info.error.max_length_website',
                          },
                          {
                            max: ITEM_WEBSITE_MAX_LENGTH,
                          },
                        )}
                      </FormHelperText>
                    )}
                  </FormControl>

                  <Stack width="100%">
                    {supportLanguages.length > 1 && (
                      <Stack direction="row" justifyContent="end">
                        <Button
                          size="small"
                          startIcon={<EditIcon />}
                          onClick={handleOpenDescriptionTranslationDialog}
                        >
                          {formatMessage({
                            id: 'portal_item_edit.button.manage_localization',
                          })}
                        </Button>
                      </Stack>
                    )}

                    <FormControl>
                      <Controller
                        name={`descriptions.${portalSetting.defaultLanguage}`}
                        control={control}
                        defaultValue=""
                        rules={{
                          maxLength: ITEM_DESCRIPTION_MAX_LENGTH,
                        }}
                        render={({ field }) => (
                          <MultiLineInput
                            {...field}
                            maxLength={ITEM_DESCRIPTION_MAX_LENGTH}
                            error={
                              !!errors?.descriptions?.[
                                portalSetting.defaultLanguage
                              ]
                            }
                            label={formatMessage(
                              {
                                id: 'portal_item_edit.step.item_info.label.description',
                              },
                              {
                                defaultLanguage: portalSetting.defaultLanguage,
                              },
                            )}
                            variant="outlined"
                            fullWidth
                            rows={3}
                          />
                        )}
                      />

                      {!!dirtyFields.descriptions &&
                        !!validateTranslations('descriptions') && (
                          <FormHelperText error>
                            {formatMessage(
                              {
                                id: 'portal_item_edit.error.missing_translations',
                              },
                              {
                                missingTranslations:
                                  validateTranslations('descriptions'),
                              },
                            )}
                          </FormHelperText>
                        )}
                    </FormControl>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>

            <Divider />

            <Stack paddingY={2} paddingX={4} spacing={2}>
              <LightSubHeader>
                {formatMessage({
                  id: 'portal_item_edit.step.item_info.label.contact_info',
                })}
              </LightSubHeader>

              <Typography variant="body2">
                {formatMessage({
                  id: 'portal_item_edit.step.item_info.text.contacts_info',
                })}
              </Typography>

              <ContactListForm
                contacts={contacts}
                onSaveContact={handleSaveContact}
                onDeleteContact={handleDeleteContact}
                onMoveContact={handleMoveContact}
              />
            </Stack>

            <Divider />

            <Stack paddingY={2} paddingX={4} spacing={2}>
              <LightSubHeader>
                {formatMessage({
                  id: 'portal_item_edit.step.report_preferences',
                })}
              </LightSubHeader>

              <Stack spacing={2} flexGrow={1}>
                {portalSetting.virtual && (
                  <FormFieldsWrapper>
                    <Typography variant="body2">
                      {formatMessage({
                        id: 'portal_item_edit.label.reporting_email',
                      })}
                    </Typography>
                    <FormControl error={!!errors.reportingEmail} fullWidth>
                      <Controller
                        name="reportingEmail"
                        control={control}
                        rules={{
                          maxLength: EMAIL_MAX_LENGTH,
                          required: true,
                        }}
                        defaultValue={''}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            error={!!errors.reportingEmail}
                            required
                            size="small"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <EmailIcon fontSize="small" />
                                </InputAdornment>
                              ),
                            }}
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />
                      {errors.reportingEmail?.type === 'maxLength' && (
                        <FormHelperText>
                          {formatMessage(
                            {
                              id: 'general.error.max_length',
                            },
                            {
                              max: EMAIL_MAX_LENGTH,
                            },
                          )}
                        </FormHelperText>
                      )}
                      {errors.reportingEmail?.type === 'required' && (
                        <FormHelperText>
                          {formatMessage({
                            id: 'general.error.required',
                          })}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </FormFieldsWrapper>
                )}

                <FormFieldsWrapper>
                  <Stack width="100%">
                    {supportLanguages.length > 1 && (
                      <Stack direction="row" justifyContent="end">
                        <Button
                          size="small"
                          startIcon={<EditIcon />}
                          onClick={handleOpenDisclaimerTranslationDialog}
                        >
                          {formatMessage({
                            id: 'portal_item_edit.button.manage_localization',
                          })}
                        </Button>
                      </Stack>
                    )}

                    <FormControl>
                      <Controller
                        name={`disclaimers.${portalSetting.defaultLanguage}`}
                        control={control}
                        rules={{
                          maxLength: ITEM_REPORT_DISCLAIMER_MAX_LENGTH,
                        }}
                        defaultValue=""
                        render={({ field }) => (
                          <MultiLineInput
                            {...field}
                            error={
                              !!errors?.disclaimers?.[
                                portalSetting.defaultLanguage
                              ]
                            }
                            maxLength={ITEM_REPORT_DISCLAIMER_MAX_LENGTH}
                            label={formatMessage(
                              {
                                id: 'portal_item_edit.step.report_preferences.label.disclaimer',
                              },
                              {
                                defaultLanguage: portalSetting.defaultLanguage,
                              },
                            )}
                            variant="outlined"
                            fullWidth
                            rows={3}
                          />
                        )}
                      />

                      {!!dirtyFields.disclaimers &&
                        !!validateTranslations('disclaimers') && (
                          <FormHelperText error>
                            {formatMessage(
                              {
                                id: 'portal_item_edit.error.missing_translations',
                              },
                              {
                                missingTranslations:
                                  validateTranslations('disclaimers'),
                              },
                            )}
                          </FormHelperText>
                        )}
                    </FormControl>
                  </Stack>
                </FormFieldsWrapper>

                <FormFieldsWrapper>
                  <Stack direction="row" alignItems="center">
                    <Box flexGrow={1}>
                      <Typography variant="body2">
                        {formatMessage({
                          id: 'portal_item_edit.label.allow_anonymous_reporting',
                        })}
                      </Typography>
                    </Box>
                    <Controller
                      name="anonymousReporting"
                      control={control}
                      defaultValue={itemData?.anonymousReporting ?? false}
                      render={({ field }) => (
                        <Switch
                          {...field}
                          checked={field.value}
                          color="primary"
                          onChange={(e) => {
                            field.onChange(e)
                            setValue('anonymousReporting', e.target.checked)
                          }}
                        />
                      )}
                    />
                  </Stack>
                </FormFieldsWrapper>
              </Stack>
            </Stack>
          </SettingWrapper>
        </Stack>
      </form>

      <TranslationDialog
        isOpen={isTranslationDialogOpen}
        title={translationDialogTitle}
        rows={translationInputRows}
        defaultValue={getTranslationDefaultLanguageValue()}
        maxLength={maxTranslationLength}
        onSave={handleSaveTranslation}
        onClose={handleCloseTranslationDialog}
      />
    </>
  )
}

export default ItemEditPage
