import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { useForm, Controller, type SubmitHandler } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'
import { useSnackbar } from 'notistack'
import LoadingButton from '@mui/lab/LoadingButton'
import {
  Box,
  Button,
  Stack,
  Typography,
  FormControl,
  TextField,
  InputAdornment,
  FormHelperText,
  Divider,
  Slider,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material'
import FeedIcon from '@mui/icons-material/Feed'
import RssFeedIcon from '@mui/icons-material/RssFeed'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import EditIcon from '@mui/icons-material/Edit'

import {
  FormFieldsWrapper,
  MainHeader,
  SubSubHeader,
  Switch,
  BackButtonGrey,
  SettingWrapper,
  LightSubHeader,
  SelectBox,
} from 'components/StyledComponents'
import useRoute from 'hooks/useNavigate'
import { type Locale } from 'types'
import {
  type ItemPayload,
  type Item,
  type ItemFormFields,
} from 'components/item/itemTypes'
import {
  PortalType,
  SHORT_TEXT_MAX_LENGTH,
  SUPPORT_REGIONS,
  SUPPORT_REGION_LABEL,
  Path,
} from '../commonConstants'
import {
  ITEM_DESCRIPTION_MAX_LENGTH,
  ITEM_FEED_URL_MAX_LENGTH,
  ITEM_ZIP_CODES_MAX_LENGTH,
  ITEM_PUBLIC_RETENTION_DAYS,
  ItemNewsSource,
  ItemType,
} from 'components/item/itemConstants'
import AddressInputWithMap from 'components/form/AddressInputWithMap'
import AddressInput from 'components/form/AddressInput'
import {
  portalSettingState,
  sortedSupportLanguagesSelector,
} from 'state/portalSettingStates'
import MultiLineInput from 'components/form/MultiLineInput'
import {
  isFileUploadingState,
  hasUnsavedChangesState,
  processedFormFilesState,
} from 'state/formStates'
import { selectedLocationInfoState, hasNewAddressState } from 'state/mapStates'
import TranslationDialog from 'components/helpers/TranslationDialog'
import useApi from 'hooks/useApi'
import {
  convertDataToLocalizedString,
  convertLocalizedStringToData,
} from 'utils/stringUtils'
import { NewsIcon } from 'components/icons/Icons'

const FORM_NAME = 'item'

const ItemAdvancedEditPage: React.FC = () => {
  const { formatMessage } = useIntl()
  const { itemId } = useParams()
  const { goTo } = useRoute()
  const supportLanguages = useRecoilValue(sortedSupportLanguagesSelector)
  const isFileUploading = useRecoilValue(isFileUploadingState(FORM_NAME))
  const processedFormFiles = useRecoilValue(processedFormFilesState(FORM_NAME))
  const selectedLocationInfo = useRecoilValue(
    selectedLocationInfoState(FORM_NAME),
  )
  const { sendPutRequest } = useApi()
  const { enqueueSnackbar } = useSnackbar()
  const [isSaving, setIsSaving] = useState(false)
  const portalSetting = useRecoilValue(portalSettingState)
  const [isTranslationDialogOpen, setIsTranslationDialogOpen] = useState(false)
  const [translationDialogTitle, setTranslationDialogTitle] = useState('')
  const [translationInputRows, setTranslationInputRows] = useState(3)
  const setHasUnsavedChanges = useSetRecoilState(hasUnsavedChangesState)
  const hasNewAddress = useRecoilValue(hasNewAddressState(FORM_NAME))

  const translationContext = useRef<
    | 'names'
    | 'descriptions'
    | 'newsFeedUrls'
    | 'eventsFeedUrls'
    | 'disclaimers'
    | null
  >(null)
  const [maxTranslationLength, setMaxTranslationLength] = useState(
    ITEM_DESCRIPTION_MAX_LENGTH,
  )
  const { data: itemData } = useSWR<Item>(
    portalSetting && itemId
      ? `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting.id
        }/items/${itemId}`
      : null,
  )
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid, isDirty, dirtyFields },
    getValues,
    trigger,
    watch,
    clearErrors,
  } = useForm<ItemFormFields>({
    mode: 'onTouched',
    defaultValues: {
      contacts: [],
      newsSource: ItemNewsSource.NEWS_POSTS,
      type: ItemType.LOCATION_BASED,
    },
  })

  // const privacyMode = watch('privacyMode')
  const publicItem = watch('publicItem')
  const publicCases = watch('publicCases')
  const names = watch('names')
  const descriptions = watch('descriptions')
  const newsFeedUrls = watch('newsFeedUrls')
  const eventsFeedUrls = watch('eventsFeedUrls')
  const disclaimers = watch('disclaimers')
  const searchRadius = watch('searchRadius')
  const newsSource = watch('newsSource')
  const itemType = watch('type')

  useEffect(() => {
    if (
      portalSetting?.mapConfiguration?.region &&
      itemType === ItemType.ZIP_CODE_BASED
    ) {
      setValue(
        'postalDetails.countryPrefix',
        portalSetting.mapConfiguration.region,
      )
    }
  }, [portalSetting])

  useEffect(() => {
    if (itemData) {
      setValue(
        'newsFeedUrls',
        convertLocalizedStringToData(itemData.newsFeedUrls),
      )
      setValue(
        'eventsFeedUrls',
        convertLocalizedStringToData(itemData.eventsFeedUrls),
      )
      setValue('newsSource', itemData.newsSource)
      setValue(
        'type',
        itemData.postalDetails
          ? ItemType.ZIP_CODE_BASED
          : ItemType.LOCATION_BASED,
      )

      if (itemData.postalDetails) {
        setValue('postalDetails', itemData.postalDetails)
      }

      setValue(
        'address',
        itemData.address || itemData.locationDetails?.location?.address || '',
      )

      if (itemData.locationDetails?.searchRadius) {
        setValue('searchRadius', itemData.locationDetails?.searchRadius)
      }
      setValue('reportingEmail', itemData.reportingEmail)
      setValue('publicItem', itemData.publicItem)
      setValue('publicCases', itemData.publicCases)
      setValue('visibleInSharedPortal', itemData.visibleInSharedPortal)
      setValue('openReporting', itemData.openReporting)
      setValue('publicCaseRetentionDays', itemData.publicCaseRetentionDays)
      setValue('notificationsDetails', itemData.notificationsDetails)
    }
  }, [itemData])

  const hasUnsavedChanges = useMemo(
    () => hasNewAddress || isDirty,
    [hasNewAddress, isDirty],
  )

  useEffect(() => {
    setHasUnsavedChanges(hasUnsavedChanges)
  }, [hasUnsavedChanges])

  useEffect(() => {
    if (!publicCases) {
      clearErrors('publicCaseRetentionDays')
    }
  }, [publicCases])

  useEffect(() => {
    if (selectedLocationInfo?.address && !itemId && !dirtyFields.address) {
      setValue('address', selectedLocationInfo.address)
    }
  }, [selectedLocationInfo?.address, itemId, dirtyFields.address])

  useEffect(() => {
    setValue('logoUrl', processedFormFiles?.[0]?.url)
  }, [processedFormFiles])

  const onSubmit: SubmitHandler<ItemFormFields> = useCallback(
    async (data): Promise<void> => {
      if (!portalSetting || !itemData) {
        return
      }

      try {
        setIsSaving(true)
        const formData: ItemPayload = {
          ...itemData,
          address: data.address,
          newsFeedUrls: convertDataToLocalizedString(data.newsFeedUrls),
          eventsFeedUrls: convertDataToLocalizedString(data.eventsFeedUrls),
          publicItem: data.publicItem ?? true,
          publicCases: data.publicCases ?? false,
          visibleInSharedPortal: data.visibleInSharedPortal ?? false,
          openReporting: data.openReporting ?? false,
          anonymousReporting: data.anonymousReporting,
          notificationsDetails: data.notificationsDetails,
          newsSource: data.newsSource,
          reportingEmail: data.reportingEmail,
          postalDetails: data.postalDetails,
          publicCaseRetentionDays: data.publicCaseRetentionDays,
        }

        if (selectedLocationInfo) {
          formData.locationDetails = {
            searchRadius: data.searchRadius,
            location: selectedLocationInfo,
          }
        }

        await sendPutRequest(
          `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
            portalSetting?.id
          }/items/${itemData.id}`,
          formData,
        )

        setHasUnsavedChanges(false)
        enqueueSnackbar(formatMessage({ id: 'general.text.changes_saved' }), {
          variant: 'success',
        })

        goTo(Path.ITEMS_LIST, true)
      } catch (error) {
        console.error(error)
      } finally {
        setIsSaving(false)
      }
    },
    [selectedLocationInfo, itemData],
  )

  const handleGoBack = (): void => {
    goTo(Path.ITEMS_LIST)
  }

  const handleOpenNewsFeedTranslationDialog = (): void => {
    setIsTranslationDialogOpen(true)
    translationContext.current = 'newsFeedUrls'
    setMaxTranslationLength(ITEM_FEED_URL_MAX_LENGTH)
    setTranslationDialogTitle(
      formatMessage({
        id: 'portal_item_edit.button.manage_feeds',
      }),
    )
    setTranslationInputRows(1)
  }

  const handleOpenEventsFeedTranslationDialog = (): void => {
    setIsTranslationDialogOpen(true)
    translationContext.current = 'eventsFeedUrls'
    setMaxTranslationLength(ITEM_FEED_URL_MAX_LENGTH)
    setTranslationDialogTitle(
      formatMessage({
        id: 'portal_item_edit.button.manage_feeds',
      }),
    )
    setTranslationInputRows(1)
  }

  const handleSaveTranslation = (data: Record<Locale, string>): void => {
    if (translationContext.current) {
      setValue(translationContext.current, data, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      })
      void trigger(translationContext.current)
    }
    setIsTranslationDialogOpen(false)
  }

  const handleCloseTranslationDialog = (): void => {
    setIsTranslationDialogOpen(false)
  }

  const getTranslationDefaultLanguageValue = useCallback((): Record<
    string,
    string
  > => {
    if (translationContext.current) {
      return getValues(translationContext.current)
    }

    return {}
  }, [
    descriptions,
    newsFeedUrls,
    eventsFeedUrls,
    disclaimers,
    names,
    translationContext.current,
  ])

  const shouldDisableSaving = useMemo(() => {
    const hasBlockingConditions =
      isSaving ||
      isFileUploading ||
      (!selectedLocationInfo && portalSetting?.type === PortalType.PROPERTY)

    return !(isValid && (hasNewAddress || !hasBlockingConditions))
  }, [
    isValid,
    isFileUploading,
    isSaving,
    hasNewAddress,
    selectedLocationInfo,
    portalSetting?.type,
  ])

  if (!portalSetting) {
    return null
  }

  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
        <Stack height={'100%'} width={'100%'}>
          <Stack direction="row" width="100%" spacing={2} marginBottom={2}>
            <Stack flexGrow={1} direction="row" spacing={1}>
              <BackButtonGrey
                onClick={handleGoBack}
                size="small"
                aria-label={formatMessage({
                  id: 'general.icon_button.go_back',
                })}
              >
                <ArrowBackIcon />
              </BackButtonGrey>

              <MainHeader>
                {formatMessage({
                  id: 'portal_item_list.menu.edit_advanced_settings',
                })}
              </MainHeader>
            </Stack>

            <LoadingButton
              variant="contained"
              size="small"
              type="submit"
              disabled={shouldDisableSaving}
              loading={isSaving}
              color="secondary"
            >
              {formatMessage({ id: 'general.button.save' })}
            </LoadingButton>
          </Stack>

          <SettingWrapper width="100%" flexGrow={1}>
            {portalSetting.type === PortalType.CITY && (
              <>
                <Stack paddingY={2} paddingX={4} spacing={2}>
                  <LightSubHeader>
                    {formatMessage({
                      id: 'portal_item_edit.step.type',
                    })}
                  </LightSubHeader>

                  <Controller
                    name="type"
                    control={control}
                    defaultValue={ItemType.LOCATION_BASED}
                    render={({ field }) => (
                      <Stack direction={'row'}>
                        <SelectBox
                          spacing={1}
                          direction={'row'}
                          alignItems="center"
                          selected={
                            itemType === ItemType.LOCATION_BASED
                              ? 'selected'
                              : ''
                          }
                          onClick={() => {
                            setValue('type', ItemType.LOCATION_BASED, {
                              shouldValidate: true,
                              shouldTouch: true,
                              shouldDirty: true,
                            })
                          }}
                        >
                          <Stack flexGrow={1} spacing={1}>
                            <SubSubHeader>
                              {formatMessage({
                                id: 'portal_item_edit.label.type.location_based',
                              })}
                            </SubSubHeader>
                          </Stack>
                        </SelectBox>
                        <SelectBox
                          spacing={1}
                          direction={'row'}
                          alignItems="center"
                          selected={
                            itemType === ItemType.ZIP_CODE_BASED
                              ? 'selected'
                              : ''
                          }
                          onClick={() => {
                            setValue('type', ItemType.ZIP_CODE_BASED, {
                              shouldValidate: true,
                              shouldTouch: true,
                              shouldDirty: true,
                            })
                          }}
                        >
                          <Stack flexGrow={1} spacing={1}>
                            <SubSubHeader>
                              {formatMessage({
                                id: 'portal_item_edit.label.type.zip_code_based',
                              })}
                            </SubSubHeader>
                          </Stack>
                        </SelectBox>
                      </Stack>
                    )}
                  />
                </Stack>

                <Divider />
              </>
            )}

            <Stack paddingY={2} paddingX={4} spacing={2}>
              <LightSubHeader>
                {formatMessage({
                  id: 'portal_item_edit.step.news',
                })}
              </LightSubHeader>

              <Controller
                name="newsSource"
                control={control}
                defaultValue={ItemNewsSource.NEWS_POSTS}
                render={({ field }) => (
                  <Stack direction={'row'}>
                    <SelectBox
                      spacing={1}
                      direction={'row'}
                      alignItems="center"
                      selected={
                        newsSource === ItemNewsSource.NEWS_POSTS
                          ? 'selected'
                          : ''
                      }
                      onClick={() => {
                        setValue('newsSource', ItemNewsSource.NEWS_POSTS, {
                          shouldValidate: true,
                          shouldTouch: true,
                          shouldDirty: true,
                        })
                      }}
                    >
                      <Stack flexGrow={1} direction="row" spacing={1}>
                        <Stack alignItems="center" justifyContent="center">
                          <NewsIcon fontSize="large" />
                        </Stack>
                        <Stack>
                          <SubSubHeader>
                            {formatMessage({
                              id: 'portal_item_edit.label.news_source.news_app',
                            })}
                          </SubSubHeader>
                          <Typography variant="caption">
                            {formatMessage({
                              id: 'portal_item_edit.description.news_source.news_app',
                            })}
                          </Typography>
                        </Stack>
                      </Stack>
                    </SelectBox>
                    <SelectBox
                      spacing={1}
                      direction={'row'}
                      alignItems="center"
                      selected={
                        newsSource === ItemNewsSource.RSS ? 'selected' : ''
                      }
                      onClick={() => {
                        setValue('newsSource', ItemNewsSource.RSS, {
                          shouldValidate: true,
                          shouldTouch: true,
                          shouldDirty: true,
                        })
                      }}
                    >
                      <Stack flexGrow={1} direction="row" spacing={1}>
                        <Stack alignItems="center" justifyContent="center">
                          <RssFeedIcon fontSize="large" />
                        </Stack>
                        <Stack>
                          <SubSubHeader>
                            {formatMessage({
                              id: 'portal_item_edit.label.news_source.rss',
                            })}
                          </SubSubHeader>
                          <Typography variant="caption">
                            {formatMessage({
                              id: 'portal_item_edit.description.news_source.rss',
                            })}
                          </Typography>
                        </Stack>
                      </Stack>
                    </SelectBox>
                  </Stack>
                )}
              />

              <Stack
                spacing={2}
                flexGrow={1}
                sx={{
                  display:
                    newsSource === ItemNewsSource.NEWS_POSTS ? 'none' : 'block',
                }}
              >
                {supportLanguages.length > 1 && (
                  <Box textAlign="right">
                    <Button
                      size="small"
                      startIcon={<EditIcon />}
                      onClick={handleOpenNewsFeedTranslationDialog}
                    >
                      {formatMessage({
                        id: 'portal_item_edit.button.manage_feeds',
                      })}
                    </Button>
                  </Box>
                )}

                <FormControl fullWidth>
                  <Controller
                    name={`newsFeedUrls.${portalSetting.defaultLanguage}`}
                    control={control}
                    defaultValue=""
                    rules={{
                      maxLength: ITEM_FEED_URL_MAX_LENGTH,
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={!!errors.names}
                        size="small"
                        label={formatMessage(
                          {
                            id: 'portal_item_edit.step.item_info.label.news_feed',
                          },
                          {
                            defaultLanguage: portalSetting.defaultLanguage,
                          },
                        )}
                        slotProps={{
                          input: {
                            startAdornment: (
                              <InputAdornment position="start">
                                <NewsIcon fontSize="small" color="primary" />
                              </InputAdornment>
                            ),
                          },
                        }}
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </FormControl>

                {supportLanguages.length > 1 && (
                  <Box textAlign="right">
                    <Button
                      size="small"
                      startIcon={<EditIcon />}
                      onClick={handleOpenEventsFeedTranslationDialog}
                    >
                      {formatMessage({
                        id: 'portal_item_edit.button.manage_feeds',
                      })}
                    </Button>
                  </Box>
                )}

                <FormControl fullWidth>
                  <Controller
                    name={`eventsFeedUrls.${portalSetting.defaultLanguage}`}
                    control={control}
                    defaultValue=""
                    rules={{
                      maxLength: ITEM_FEED_URL_MAX_LENGTH,
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={!!errors.names}
                        size="small"
                        label={formatMessage(
                          {
                            id: 'portal_item_edit.step.item_info.label.events_feed',
                          },
                          {
                            defaultLanguage: portalSetting.defaultLanguage,
                          },
                        )}
                        slotProps={{
                          input: {
                            startAdornment: (
                              <InputAdornment position="start">
                                <FeedIcon fontSize="small" color="primary" />
                              </InputAdornment>
                            ),
                          },
                        }}
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </FormControl>
              </Stack>
            </Stack>

            <Divider />

            <Stack
              paddingY={2}
              paddingX={4}
              spacing={2}
              width={'100%'}
              data-testid="portal-preferences"
            >
              <Stack spacing={2} width="100%">
                {itemType === ItemType.LOCATION_BASED && (
                  <Stack width="100%" spacing={1}>
                    <SubSubHeader>
                      {formatMessage({
                        id: 'portal_item_edit.step.item_info.label.location',
                      })}{' '}
                      *
                    </SubSubHeader>
                    <Typography variant="body2">
                      {formatMessage({
                        id: 'portal_item_edit.step.item_info.info.location',
                      })}
                    </Typography>

                    {portalSetting && (
                      <AddressInputWithMap
                        mapHeight={400}
                        selectedPosition={
                          itemData?.locationDetails?.location?.position
                        }
                        center={portalSetting.mapConfiguration?.centre}
                        markerRadius={searchRadius}
                        region={portalSetting.mapConfiguration?.region}
                        language={portalSetting.defaultLanguage.toLowerCase()}
                        zoom={portalSetting.mapConfiguration?.zoomLevel}
                        formName={FORM_NAME}
                      />
                    )}

                    <FormFieldsWrapper>
                      <Stack direction="row">
                        <Box width={300}>
                          <Typography variant="body2">
                            {formatMessage({
                              id: 'portal_item_edit.step.item_info.info.search_range',
                            })}
                          </Typography>
                        </Box>
                        <Box flexGrow={1}>
                          <Controller
                            name="searchRadius"
                            control={control}
                            defaultValue={50}
                            render={({ field }) => (
                              <Slider
                                {...field}
                                step={25}
                                size="small"
                                min={25}
                                max={1000}
                                valueLabelDisplay="on"
                                marks={[
                                  {
                                    value: 25,
                                    label: '25m',
                                  },
                                  {
                                    value: 1000,
                                    label: '1km',
                                  },
                                ]}
                              />
                            )}
                          />
                        </Box>
                      </Stack>
                    </FormFieldsWrapper>
                  </Stack>
                )}

                {itemType === ItemType.ZIP_CODE_BASED && (
                  <Stack width="100%" spacing={1}>
                    <SubSubHeader>
                      {formatMessage({
                        id: 'portal_item_edit.step.item_info.label.area',
                      })}{' '}
                    </SubSubHeader>
                    <Typography variant="body2">
                      {formatMessage({
                        id: 'portal_item_edit.step.item_info.text.info_area',
                      })}
                    </Typography>
                    <FormControl
                      fullWidth
                      error={!!errors.postalDetails?.countryPrefix}
                    >
                      <InputLabel id="country-code-label" size="small" required>
                        {formatMessage({
                          id: 'portal_item_edit.label.country_code',
                        })}
                      </InputLabel>
                      <Controller
                        name="postalDetails.countryPrefix"
                        control={control}
                        rules={{
                          required: true,
                        }}
                        defaultValue={
                          itemData?.postalDetails?.countryPrefix ??
                          SUPPORT_REGIONS[0]
                        }
                        render={({ field }) => (
                          <Select
                            {...field}
                            labelId="country-code-label"
                            label={formatMessage({
                              id: 'portal_item_edit.label.country_code',
                            })}
                            size="small"
                            fullWidth
                            variant="outlined"
                          >
                            {SUPPORT_REGIONS.map((region) => (
                              <MenuItem key={region} value={region}>
                                {formatMessage({
                                  id: SUPPORT_REGION_LABEL[region],
                                })}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                    <FormControl error={!!errors.postalDetails?.postalCodes}>
                      <Controller
                        name="postalDetails.postalCodes"
                        control={control}
                        rules={{
                          maxLength: ITEM_ZIP_CODES_MAX_LENGTH,
                          required: true,
                        }}
                        defaultValue=""
                        render={({ field }) => (
                          <MultiLineInput
                            {...field}
                            required
                            maxLength={ITEM_ZIP_CODES_MAX_LENGTH}
                            label={formatMessage({
                              id: 'portal_item_edit.step.item_info.label.zip_codes',
                            })}
                            variant="outlined"
                            fullWidth
                            rows={3}
                            error={!!errors.postalDetails?.postalCodes}
                            helpMessage={formatMessage({
                              id: 'portal_item_edit.help_text.zip_codes',
                            })}
                          />
                        )}
                      />
                    </FormControl>
                  </Stack>
                )}

                <SubSubHeader>
                  {formatMessage({
                    id: 'portal_item_edit.step.item_info.label.address',
                  })}{' '}
                  {itemType === ItemType.LOCATION_BASED && '*'}
                </SubSubHeader>
                <Typography variant="body2">
                  {formatMessage({
                    id: 'portal_item_edit.city.info.address',
                  })}
                </Typography>

                <FormFieldsWrapper>
                  <FormControl error={!!errors.address} fullWidth>
                    <Controller
                      name="address"
                      control={control}
                      rules={{
                        maxLength: SHORT_TEXT_MAX_LENGTH,
                        required: itemType === ItemType.LOCATION_BASED,
                      }}
                      defaultValue={''}
                      render={({ field }) => (
                        <AddressInput
                          {...field}
                          region={portalSetting?.mapConfiguration?.region}
                          language={portalSetting?.defaultLanguage.toLowerCase()}
                          required={portalSetting.type === PortalType.PROPERTY}
                          error={!!errors.address}
                        />
                      )}
                    />
                    {errors.address?.type === 'maxLength' && (
                      <FormHelperText>
                        {formatMessage(
                          {
                            id: 'general.error.max_length',
                          },
                          {
                            max: SHORT_TEXT_MAX_LENGTH,
                          },
                        )}
                      </FormHelperText>
                    )}
                    {errors.address?.type === 'required' && (
                      <FormHelperText>
                        {formatMessage({
                          id: 'general.error.required',
                        })}
                      </FormHelperText>
                    )}
                  </FormControl>
                </FormFieldsWrapper>
              </Stack>
            </Stack>

            <Divider />

            <Stack paddingY={2} paddingX={4} spacing={2}>
              <LightSubHeader>
                {formatMessage({
                  id: 'portal_item_edit.visibility.title',
                })}
              </LightSubHeader>

              <FormFieldsWrapper>
                <Stack direction="row" alignItems="center">
                  <Box flexGrow={1}>
                    <SubSubHeader>
                      {formatMessage({
                        id: 'portal_item_edit.public_item.title',
                      })}
                    </SubSubHeader>

                    <Typography variant="body2">
                      {formatMessage({
                        id: 'portal_item_edit.public_item.desc',
                      })}
                    </Typography>
                  </Box>
                  <Controller
                    name="publicItem"
                    control={control}
                    defaultValue={itemData?.publicItem ?? false}
                    render={({ field }) => (
                      <Switch
                        {...field}
                        checked={field.value}
                        color="primary"
                        onChange={(e) => {
                          field.onChange(e)
                          setValue('publicItem', e.target.checked)
                        }}
                      />
                    )}
                  />
                </Stack>

                {publicItem && (
                  <Stack direction="row" alignItems="center">
                    <Box flexGrow={1}>
                      <SubSubHeader>
                        {formatMessage({
                          id: 'portal_item_edit.open_reporting.title',
                        })}
                      </SubSubHeader>

                      <Typography variant="body2">
                        {formatMessage({
                          id: 'portal_item_edit.open_reporting.desc',
                        })}
                      </Typography>
                    </Box>
                    <Controller
                      name="openReporting"
                      control={control}
                      defaultValue={itemData?.openReporting ?? false}
                      render={({ field }) => (
                        <Switch
                          {...field}
                          checked={field.value}
                          color="primary"
                          onChange={(e) => {
                            field.onChange(e)
                            setValue('openReporting', e.target.checked)
                          }}
                        />
                      )}
                    />
                  </Stack>
                )}
              </FormFieldsWrapper>

              <FormFieldsWrapper>
                <Stack direction="row" alignItems="center">
                  <Box flexGrow={1}>
                    <SubSubHeader>
                      {formatMessage({
                        id: 'portal_item_edit.public_cases.title',
                      })}
                    </SubSubHeader>

                    <Typography variant="body2">
                      {formatMessage({
                        id: 'portal_item_edit.public_cases.desc',
                      })}
                    </Typography>
                  </Box>
                  <Controller
                    name="publicCases"
                    control={control}
                    defaultValue={itemData?.publicCases ?? false}
                    render={({ field }) => (
                      <Switch
                        {...field}
                        checked={field.value}
                        color="primary"
                        onChange={(e) => {
                          field.onChange(e)
                          setValue('publicCases', e.target.checked)
                        }}
                      />
                    )}
                  />
                </Stack>

                {publicCases && (
                  <FormFieldsWrapper>
                    <SubSubHeader>
                      {formatMessage({
                        id: 'portal_item_edit.subheader.visibility_period',
                      })}
                    </SubSubHeader>
                    <Stack
                      paddingY={2}
                      paddingX={4}
                      direction="row"
                      alignItems="center"
                      spacing={2}
                    >
                      <Box flexGrow={1}>
                        <Typography variant="body2">
                          {formatMessage({
                            id: 'portal_item_edit.visibility_period.description',
                          })}
                        </Typography>
                      </Box>
                      <FormControl error={!!errors.publicCaseRetentionDays}>
                        <Controller
                          name="publicCaseRetentionDays"
                          control={control}
                          rules={{
                            validate: (val): boolean => {
                              if (
                                !!val &&
                                !Number.isNaN(Number(val)) &&
                                val > 0
                              ) {
                                return true
                              }
                              return false
                            },
                          }}
                          defaultValue={ITEM_PUBLIC_RETENTION_DAYS}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              required={true}
                              type="number"
                              error={!!errors.publicCaseRetentionDays}
                              size="small"
                              InputProps={{
                                endAdornment: formatMessage({
                                  id: 'portal_item_edit.visibility_period.days',
                                }),
                              }}
                              variant="outlined"
                            />
                          )}
                        />
                        {!!errors.publicCaseRetentionDays && (
                          <FormHelperText>
                            {formatMessage({
                              id: 'portal_item_edit.visibility_period.days.error',
                            })}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Stack>
                  </FormFieldsWrapper>
                )}
              </FormFieldsWrapper>

              <FormFieldsWrapper>
                <Stack direction="row" alignItems="center">
                  <Box flexGrow={1}>
                    <SubSubHeader>
                      {formatMessage({
                        id: 'portal_item_edit.visible_on_public_page.title',
                      })}
                    </SubSubHeader>

                    <Typography variant="body2">
                      {formatMessage({
                        id: 'portal_item_edit.visible_on_public_page.desc',
                      })}
                    </Typography>
                  </Box>
                  <Controller
                    name="visibleInSharedPortal"
                    control={control}
                    defaultValue={itemData?.visibleInSharedPortal ?? false}
                    render={({ field }) => (
                      <Switch
                        {...field}
                        checked={field.value}
                        color="primary"
                        onChange={(e) => {
                          field.onChange(e)
                          setValue('visibleInSharedPortal', e.target.checked)
                        }}
                      />
                    )}
                  />
                </Stack>
              </FormFieldsWrapper>
            </Stack>
            <Divider />

            <Stack paddingY={2} paddingX={4} spacing={2}>
              <LightSubHeader>
                {formatMessage({
                  id: 'portal_item_edit.label.auto_email_notifications',
                })}
              </LightSubHeader>

              <Typography variant="body2">
                {formatMessage({
                  id: 'portal_item_edit.text.auto_email_notification_info',
                })}
              </Typography>
              <FormFieldsWrapper>
                <Stack direction="row" alignItems="center">
                  <Box flexGrow={1}>
                    <Typography variant="body2">
                      {formatMessage({
                        id: 'portal_item_edit.label.send_notification_when_case_created',
                      })}
                    </Typography>
                  </Box>
                  <Controller
                    name="notificationsDetails.emailToReporterWhenCaseCreated"
                    control={control}
                    defaultValue={
                      itemData?.notificationsDetails
                        .emailToReporterWhenCaseCreated ?? false
                    }
                    render={({ field }) => (
                      <Switch
                        {...field}
                        checked={field.value}
                        color="primary"
                        onChange={(e) => {
                          field.onChange(e)
                          setValue(
                            'notificationsDetails.emailToReporterWhenCaseCreated',
                            e.target.checked,
                          )
                        }}
                      />
                    )}
                  />
                </Stack>
                <Stack direction="row" alignItems="center">
                  <Box flexGrow={1}>
                    <Typography variant="body2">
                      {formatMessage({
                        id: 'portal_item_edit.label.send_notification_when_comment_added',
                      })}
                    </Typography>
                  </Box>
                  <Controller
                    name="notificationsDetails.emailToReporterWhenCaseCommentedByMember"
                    control={control}
                    defaultValue={
                      itemData?.notificationsDetails
                        .emailToReporterWhenCaseCommentedByMember ?? false
                    }
                    render={({ field }) => (
                      <Switch
                        {...field}
                        checked={field.value}
                        color="primary"
                        onChange={(e) => {
                          field.onChange(e)
                          setValue(
                            'notificationsDetails.emailToReporterWhenCaseCommentedByMember',
                            e.target.checked,
                          )
                        }}
                      />
                    )}
                  />
                </Stack>
              </FormFieldsWrapper>
            </Stack>
          </SettingWrapper>
        </Stack>
      </form>

      <TranslationDialog
        isOpen={isTranslationDialogOpen}
        title={translationDialogTitle}
        rows={translationInputRows}
        defaultValue={getTranslationDefaultLanguageValue()}
        maxLength={maxTranslationLength}
        onSave={handleSaveTranslation}
        onClose={handleCloseTranslationDialog}
      />
    </>
  )
}

export default ItemAdvancedEditPage
