import { useCallback, useState, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import useSWR from 'swr'
import { useSnackbar } from 'notistack'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { useForm, type SubmitHandler, Controller } from 'react-hook-form'
import Stack from '@mui/material/Stack'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import LoadingButton from '@mui/lab/LoadingButton'
import Box from '@mui/material/Box'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

import {
  BackButtonGrey,
  LightSubHeader,
  MainHeader,
  SettingWrapper,
} from 'components/StyledComponents'
import useRoute from 'hooks/useNavigate'
import {
  hasUnsavedChangesState,
  isFileUploadingState,
  processedFormFilesState,
} from 'state/formStates'
import { portalSettingState } from 'state/portalSettingStates'
import {
  TEAM_NAME_MAX_LENGTH,
  TEAM_DESCRIPTION_MAX_LENGTH,
  Path,
} from '../commonConstants'
import useApi from 'hooks/useApi'
import { type NewTeam, type Team } from 'types'
import ImageUploader from 'components/form/ImageUploader'
import MultiLineInput from 'components/form/MultiLineInput'

const FORM_NAME = 'editTeamForm'

const MAX_IMAGE_WIDTH_IN_PX_FOR_UPLOAD = 300
const MAX_IMAGE_HEIGHT_IN_PX_FOR_UPLOAD = 300

const TeamEditPage: React.FC = () => {
  const { formatMessage } = useIntl()
  const { teamId } = useParams()
  const { goTo } = useRoute()
  const { enqueueSnackbar } = useSnackbar()
  const [isSaving, setIsSaving] = useState(false)

  const setHasUnsavedChanges = useSetRecoilState(hasUnsavedChangesState)
  const portalSetting = useRecoilValue(portalSettingState)
  const isFileUploading = useRecoilValue(isFileUploadingState(FORM_NAME))
  const processedFormFiles = useRecoilValue(processedFormFilesState(FORM_NAME))
  const { sendPostRequest, sendPutRequest } = useApi()
  const { data: teamData } = useSWR<Team>(
    portalSetting && teamId
      ? `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting.id
        }/teams/${teamId}`
      : null,
  )
  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    setValue,
  } = useForm<NewTeam>({
    mode: 'onTouched',
  })

  useEffect(() => {
    if (teamData) {
      setValue('name', teamData.name)
      setValue('description', teamData.description)
    }
  }, [teamData])

  const onSubmit: SubmitHandler<NewTeam> = useCallback(
    async (data): Promise<void> => {
      if (portalSetting) {
        try {
          setIsSaving(true)
          const formData = {
            ...data,
          }

          if (processedFormFiles.length) {
            formData.logoUrl = processedFormFiles[0].url
          }

          if (teamId) {
            await sendPutRequest(
              `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
                portalSetting.id
              }/teams/${teamId}`,
              formData,
            )
          } else {
            await sendPostRequest(
              `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
                portalSetting.id
              }/teams`,
              formData,
            )
          }

          enqueueSnackbar(
            formatMessage({
              id: 'general.text.changes_saved',
            }),
            {
              variant: 'success',
            },
          )

          goTo(Path.TEAMS_LIST, true)
        } catch (error) {
          console.error(error)
        } finally {
          setIsSaving(false)
          setHasUnsavedChanges(false)
        }
      }
    },
    [portalSetting, teamId, processedFormFiles],
  )

  const handleGoBack = (): void => {
    goTo(Path.TEAMS_LIST)
  }

  const hasUnsavedChanges = useMemo(() => isDirty, [isDirty])

  const shouldDisableSaving = useMemo(
    () => !isValid || isSaving || isFileUploading,
    [isValid, isSaving, isFileUploading],
  )

  useEffect(() => {
    setHasUnsavedChanges(hasUnsavedChanges)
  }, [hasUnsavedChanges])

  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
        <Stack height={'100%'} width={'100%'}>
          <Stack direction="row" width="100%" spacing={2} marginBottom={2}>
            <Stack flexGrow={1} direction="row" spacing={1}>
              <BackButtonGrey
                onClick={handleGoBack}
                size="small"
                aria-label={formatMessage({
                  id: 'general.icon_button.go_back',
                })}
              >
                <ArrowBackIcon />
              </BackButtonGrey>

              <MainHeader>
                {formatMessage({
                  id: teamId ? 'team_edit.header' : 'team_add.header',
                })}
              </MainHeader>
            </Stack>

            <LoadingButton
              variant="contained"
              size="small"
              type="submit"
              disabled={shouldDisableSaving}
              loading={isSaving}
              color="secondary"
            >
              {formatMessage({ id: 'general.button.save' })}
            </LoadingButton>
          </Stack>

          <SettingWrapper width="100%" flexGrow={1}>
            <Stack paddingY={2} paddingX={4} spacing={2}>
              <LightSubHeader>
                {formatMessage({
                  id: 'team_edit.subheader.team_information',
                })}
              </LightSubHeader>

              <Stack direction="row" spacing={2} width="100%">
                <Box width="220px" height="220px">
                  <ImageUploader
                    formName={FORM_NAME}
                    maxImageWidth={MAX_IMAGE_WIDTH_IN_PX_FOR_UPLOAD}
                    maxImageHeight={MAX_IMAGE_HEIGHT_IN_PX_FOR_UPLOAD}
                    defaultImageUrl={teamData?.logoUrl}
                  />
                </Box>

                <Stack spacing={2} flexGrow={1}>
                  <FormControl error={!!errors.name} fullWidth>
                    <Controller
                      name="name"
                      control={control}
                      rules={{
                        required: true,
                        maxLength: TEAM_NAME_MAX_LENGTH,
                      }}
                      defaultValue={''}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          error={!!errors.name}
                          required
                          autoFocus
                          size="small"
                          label={formatMessage({
                            id: 'team_edit.label.team_name',
                          })}
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                  </FormControl>

                  <FormControl error={!!errors.description}>
                    <Controller
                      name="description"
                      control={control}
                      defaultValue=""
                      rules={{
                        maxLength: TEAM_DESCRIPTION_MAX_LENGTH,
                      }}
                      render={({ field }) => (
                        <MultiLineInput
                          {...field}
                          error={!!errors.description}
                          maxLength={TEAM_DESCRIPTION_MAX_LENGTH}
                          label={formatMessage({
                            id: 'team_edit.label.team_description',
                          })}
                          variant="outlined"
                          fullWidth
                          rows={3}
                        />
                      )}
                    />
                  </FormControl>
                </Stack>
              </Stack>
            </Stack>
          </SettingWrapper>
        </Stack>
      </form>
    </>
  )
}

export default TeamEditPage
