import { useCallback, useRef, useState, useMemo, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Controller, useForm, useFieldArray } from 'react-hook-form'
import { useRecoilValue } from 'recoil'
import styled from '@mui/material/styles/styled'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import FormHelperText from '@mui/material/FormHelperText'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import PhoneIcon from '@mui/icons-material/Phone'
import EmailIcon from '@mui/icons-material/Email'
import AddressIcon from '@mui/icons-material/LocationOn'
import WebsiteIcon from '@mui/icons-material/Language'
import DeleteIcon from '@mui/icons-material/Delete'
import MyLocationIcon from '@mui/icons-material/MyLocation'

import { type ContactFormField } from 'components/item/itemTypes'
import {
  portalSettingState,
  sortedSupportLanguagesSelector,
} from 'state/portalSettingStates'
import { type LocationInfo, type Locale } from 'types'
import TranslationDialog from 'components/helpers/TranslationDialog'
import {
  ITEM_CONTACT_NAME_MAX_LENGTH,
  ITEM_CONTACT_DESCRIPTION_MAX_LENGTH,
  ContactDetailType,
  ITEM_WEBSITE_MAX_LENGTH,
} from 'components/item/itemConstants'
import MultiLineInput from 'components/form/MultiLineInput'
import {
  ADDRESS_MAX_LENGTH,
  EMAIL_MAX_LENGTH,
  EMERGENCY_PHONE_NUMBERS,
  PHONE_MAX_LENGTH,
} from 'commonConstants'
import { isValidEmail, isValidPhoneNumber } from 'utils/stringUtils'
import AddressInputWithMap from 'components/form/AddressInputWithMap'
import { Divider } from '@mui/material'

type ContactEditFormProps = {
  contact?: ContactFormField
  onSave: (data: ContactFormField) => void
  onClose: () => void
}

const Wrapper = styled(Stack)`
  background: ${({ theme }) => theme.palette.primary.light};
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  border: 1.5px solid;
  border-color: ${({ theme }) => theme.palette.primary.main};
  box-shadow: 0px 0px 0px 3px #bbc6f1;
  padding: ${({ theme }) => theme.spacing(2)};
  gap: ${({ theme }) => theme.spacing(1)};

  & .MuiInputBase-root {
    background-color: ${({ theme }) => theme.palette.background.paper};
  }
`

const ContactSelect = styled(Select)`
  > .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  > .MuiInputBase-input {
    padding: 0;
    padding-top: 5px;
  }
`

const FORM_NAME = 'ITEM_CONTACT_EDIT'

const ContactEditForm: React.FC<ContactEditFormProps> = ({
  contact,
  onSave,
  onClose,
}) => {
  const { formatMessage } = useIntl()
  const portalSetting = useRecoilValue(portalSettingState)
  const supportLanguages = useRecoilValue(sortedSupportLanguagesSelector)
  const [isTranslationDialogOpen, setIsTranslationDialogOpen] = useState(false)
  const [translationDialogTitle, setTranslationDialogTitle] = useState('')
  const [translationInputRows, setTranslationInputRows] = useState(3)
  const translationContext = useRef<
    'names' | 'descriptions' | `details.${number}.texts` | null
  >(null)
  const [maxTranslationLength, setMaxTranslationLength] = useState(
    ITEM_CONTACT_NAME_MAX_LENGTH,
  )
  const [hasAddressConfirmed, setHasAddressConfirmed] = useState(false)
  const {
    control,
    getValues,
    setValue,
    formState: { errors, isValid, dirtyFields },
    watch,
    trigger,
  } = useForm<ContactFormField>({
    mode: 'onTouched',
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'details',
  })

  const names = watch('names')
  const descriptions = watch('descriptions')
  const details = watch('details')

  useEffect((): void => {
    if (contact) {
      setValue('names', contact.names)
      setValue('descriptions', contact.descriptions)
      setValue('details', contact.details)
      setValue('id', contact.id)

      const addressDetail = contact.details.find(
        (detail) => detail.type === ContactDetailType.ADDRESS,
      )

      if (addressDetail?.position) {
        setHasAddressConfirmed(true)
      }
      void trigger()
    }
  }, [contact])

  const validateTranslations = (
    name: 'names' | 'descriptions' | `details.${number}.texts`,
  ): null | string => {
    if (!portalSetting) {
      return null
    }

    const data = getValues(name)

    let missingTranslations: Locale[] = [...portalSetting.supportedLanguages]

    if (data) {
      const keys = Object.keys(data)
      keys.forEach((key) => {
        if (data[key]) {
          missingTranslations = missingTranslations.filter(
            (item) => item !== key,
          )
        }
      })

      if (missingTranslations.length) {
        return missingTranslations.join(', ')
      }
    }

    return null
  }

  const handleOpenNameTranslationDialog = (): void => {
    setIsTranslationDialogOpen(true)
    translationContext.current = 'names'
    setMaxTranslationLength(ITEM_CONTACT_NAME_MAX_LENGTH)
    setTranslationDialogTitle(
      formatMessage({
        id: 'portal_item_edit.button.manage_localization',
      }),
    )
    setTranslationInputRows(1)
  }

  const handleOpenDescriptionTranslationDialog = (): void => {
    setIsTranslationDialogOpen(true)
    translationContext.current = 'descriptions'
    setMaxTranslationLength(ITEM_CONTACT_DESCRIPTION_MAX_LENGTH)
    setTranslationDialogTitle(
      formatMessage({
        id: 'portal_item_edit.button.manage_localization',
      }),
    )
    setTranslationInputRows(3)
  }

  const handleOpenDetailTranslationDialog = useCallback(
    (index: number): void => {
      setIsTranslationDialogOpen(true)
      translationContext.current = `details.${index}.texts`

      let maxLength = PHONE_MAX_LENGTH
      if (details?.[index]?.type === ContactDetailType.WEBSITE) {
        maxLength = ITEM_WEBSITE_MAX_LENGTH
      } else if (details?.[index]?.type === ContactDetailType.EMAIL) {
        maxLength = EMAIL_MAX_LENGTH
      } else if (details?.[index]?.type === ContactDetailType.ADDRESS) {
        maxLength = ADDRESS_MAX_LENGTH
      }
      setMaxTranslationLength(maxLength)
      setTranslationDialogTitle(
        formatMessage({
          id: 'portal_item_edit.button.manage_localization',
        }),
      )
      setTranslationInputRows(1)
    },
    [details],
  )

  const getTranslationDefaultLanguageValue = useCallback((): Record<
    string,
    string
  > => {
    if (translationContext.current) {
      return getValues(translationContext.current)
    }

    return {}
  }, [descriptions, names, translationContext.current])

  const handleSaveTranslation = (data: Record<Locale, string>): void => {
    if (translationContext.current) {
      setValue(translationContext.current, data, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      })
      void trigger(translationContext.current)
    }
    setIsTranslationDialogOpen(false)
  }

  const handleCloseTranslationDialog = (): void => {
    setIsTranslationDialogOpen(false)
  }

  const missingNamesTranslation = useMemo(
    () => !!dirtyFields.names && !!validateTranslations('names'),
    [JSON.stringify(dirtyFields.names)],
  )

  const missingDetailsTranslation = useMemo(() => {
    let result = false
    details?.forEach((detail, index) => {
      if (
        !!dirtyFields.details?.[index] &&
        !!validateTranslations(`details.${index}.texts`)
      ) {
        result = true
      }
    })

    return result
  }, [JSON.stringify(details), JSON.stringify(dirtyFields.details)])

  const shouldDisableSaving = useMemo(
    () => !isValid || missingNamesTranslation || missingDetailsTranslation,
    [isValid, missingNamesTranslation, missingDetailsTranslation],
  )

  const handleSave = (): void => {
    onSave(getValues())
    onClose()
  }

  const handleAddDetail = useCallback(async (): Promise<void> => {
    if (portalSetting) {
      append({
        type: ContactDetailType.PHONE,
        texts: { [portalSetting.defaultLanguage]: '' },
      })
    }
  }, [portalSetting, dirtyFields, details])

  const hasAddressDetail = useMemo(() => {
    return details?.some((info) => info.type === ContactDetailType.ADDRESS)
  }, [JSON.stringify(details)])

  const handleConfirmLocation = (location: LocationInfo): void => {
    const existedAddressDetail = details.find(
      (detail) => detail.type === ContactDetailType.ADDRESS && !!detail.texts,
    )
    const restDetails = details.filter(
      (detail) => detail.type !== ContactDetailType.ADDRESS,
    )
    const position = {
      lat: location.position.lat,
      lng: location.position.lng,
    }

    const newTexts: Record<string, string> = {}
    portalSetting?.supportedLanguages.forEach(
      (language) =>
        (newTexts[language] =
          existedAddressDetail?.texts[language] || location.address),
    )

    const newDetails = [
      ...restDetails,
      {
        type: ContactDetailType.ADDRESS,
        texts: newTexts,
        position,
      },
    ]

    setValue('details', newDetails)
    setHasAddressConfirmed(true)
  }

  if (!portalSetting) {
    return null
  }

  return (
    <Wrapper>
      <Stack width="100%">
        {supportLanguages.length > 1 && (
          <Stack direction="row" justifyContent="end">
            <Button
              size="small"
              startIcon={<EditIcon />}
              onClick={handleOpenNameTranslationDialog}
            >
              {formatMessage({
                id: 'portal_item_edit.button.manage_localization',
              })}
            </Button>
          </Stack>
        )}

        <Controller
          name={`names.${portalSetting.defaultLanguage}`}
          control={control}
          rules={{
            maxLength: ITEM_CONTACT_NAME_MAX_LENGTH,
            required: true,
          }}
          defaultValue={''}
          render={({ field }) => (
            <TextField
              {...field}
              required
              error={!!errors.names}
              size="small"
              label={formatMessage({
                id: 'contact_edit_dialog.placeholder.title',
              })}
              variant="outlined"
              fullWidth
            />
          )}
        />
        {errors.names?.[portalSetting.defaultLanguage]?.type === 'required' && (
          <FormHelperText error>
            {formatMessage(
              {
                id: 'general.error.required',
              },
              {
                max: ITEM_CONTACT_NAME_MAX_LENGTH,
              },
            )}
          </FormHelperText>
        )}

        {errors.names?.[portalSetting.defaultLanguage]?.type ===
          'maxLength' && (
          <FormHelperText error>
            {formatMessage(
              {
                id: 'general.error.max_length',
              },
              {
                max: ITEM_CONTACT_NAME_MAX_LENGTH,
              },
            )}
          </FormHelperText>
        )}

        {!!dirtyFields.names && !!validateTranslations('names') && (
          <FormHelperText error>
            {formatMessage(
              {
                id: 'portal_item_edit.error.missing_translations',
              },
              {
                missingTranslations: validateTranslations('names'),
              },
            )}
          </FormHelperText>
        )}
      </Stack>

      <Stack width="100%">
        {supportLanguages.length > 1 && (
          <Stack direction="row" justifyContent="end">
            <Button
              size="small"
              startIcon={<EditIcon />}
              onClick={handleOpenDescriptionTranslationDialog}
            >
              {formatMessage({
                id: 'portal_item_edit.button.manage_localization',
              })}
            </Button>
          </Stack>
        )}

        <FormControl>
          <Controller
            name={`descriptions.${portalSetting.defaultLanguage}`}
            control={control}
            defaultValue=""
            rules={{
              maxLength: ITEM_CONTACT_DESCRIPTION_MAX_LENGTH,
            }}
            render={({ field }) => (
              <MultiLineInput
                {...field}
                maxLength={ITEM_CONTACT_DESCRIPTION_MAX_LENGTH}
                error={!!errors?.descriptions?.[portalSetting.defaultLanguage]}
                label={formatMessage(
                  {
                    id: 'portal_item_edit.contact.label.description',
                  },
                  {
                    defaultLanguage: portalSetting.defaultLanguage,
                  },
                )}
                variant="outlined"
                fullWidth
                rows={3}
              />
            )}
          />

          {!!dirtyFields.descriptions &&
            !!validateTranslations('descriptions') && (
              <FormHelperText error>
                {formatMessage(
                  {
                    id: 'portal_item_edit.error.missing_translations',
                  },
                  {
                    missingTranslations: validateTranslations('descriptions'),
                  },
                )}
              </FormHelperText>
            )}
        </FormControl>
      </Stack>

      {fields.map((field, index) => (
        <Stack key={field.id}>
          {supportLanguages.length > 1 && (
            <Stack direction="row" justifyContent="end">
              <Button
                size="small"
                startIcon={<EditIcon />}
                onClick={(): void => {
                  handleOpenDetailTranslationDialog(index)
                }}
              >
                {formatMessage({
                  id: 'portal_item_edit.button.manage_localization',
                })}
              </Button>
            </Stack>
          )}

          <FormControl fullWidth error={!!errors?.details?.[index]?.texts}>
            <Controller
              name={`details.${index}.texts.${portalSetting.defaultLanguage}`}
              control={control}
              rules={{
                required: true,
                maxLength:
                  details?.[index]?.type === ContactDetailType.PHONE
                    ? PHONE_MAX_LENGTH
                    : details?.[index]?.type === ContactDetailType.EMAIL
                    ? EMAIL_MAX_LENGTH
                    : details?.[index]?.type === ContactDetailType.ADDRESS
                    ? ADDRESS_MAX_LENGTH
                    : ITEM_WEBSITE_MAX_LENGTH,

                validate: (val): boolean => {
                  if (!val || !details?.[index]) {
                    return true
                  }

                  const { type } = details[index]

                  if (type === ContactDetailType.PHONE) {
                    return (
                      EMERGENCY_PHONE_NUMBERS.includes(val) ||
                      isValidPhoneNumber(val)
                    )
                  }

                  if (type === ContactDetailType.EMAIL) {
                    return isValidEmail(val)
                  }

                  return true
                },
              }}
              defaultValue={''}
              render={({ field }) => (
                <TextField
                  {...field}
                  disabled={
                    !hasAddressConfirmed &&
                    details[index].type === ContactDetailType.ADDRESS
                  }
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Controller
                          name={`details.${index}.type`}
                          control={control}
                          defaultValue={ContactDetailType.PHONE}
                          render={({ field: selectField }) => (
                            <ContactSelect {...selectField}>
                              <MenuItem value={ContactDetailType.PHONE}>
                                <PhoneIcon fontSize="small" />
                              </MenuItem>
                              <MenuItem value={ContactDetailType.EMAIL}>
                                <EmailIcon fontSize="small" />
                              </MenuItem>
                              <MenuItem value={ContactDetailType.WEBSITE}>
                                <WebsiteIcon fontSize="small" />
                              </MenuItem>
                              {(!hasAddressDetail ||
                                selectField.value ===
                                  ContactDetailType.ADDRESS) && (
                                <MenuItem value={ContactDetailType.ADDRESS}>
                                  <AddressIcon fontSize="small" />
                                </MenuItem>
                              )}
                            </ContactSelect>
                          )}
                        />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        {hasAddressConfirmed &&
                          details[index].type === ContactDetailType.ADDRESS && (
                            <>
                              <IconButton
                                aria-label="delete"
                                size="small"
                                onClick={(): void => {
                                  setHasAddressConfirmed(false)
                                }}
                              >
                                <MyLocationIcon fontSize="inherit" />
                              </IconButton>
                              <Divider
                                orientation="vertical"
                                variant="middle"
                                flexItem
                              />
                            </>
                          )}

                        <IconButton
                          aria-label="delete"
                          size="small"
                          onClick={(): void => {
                            if (
                              details[index].type === ContactDetailType.ADDRESS
                            ) {
                              setHasAddressConfirmed(false)
                            }
                            remove(index)
                          }}
                        >
                          <DeleteIcon fontSize="inherit" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  fullWidth
                />
              )}
            />

            {!!dirtyFields.details?.[index] &&
              !!validateTranslations(`details.${index}.texts`) && (
                <FormHelperText error>
                  {formatMessage(
                    {
                      id: 'portal_item_edit.error.missing_translations',
                    },
                    {
                      missingTranslations: validateTranslations(
                        `details.${index}.texts`,
                      ),
                    },
                  )}
                </FormHelperText>
              )}

            {details?.[index]?.type === ContactDetailType.ADDRESS &&
              !hasAddressConfirmed && (
                <AddressInputWithMap
                  selectedPosition={details?.[index]?.position || undefined}
                  mapHeight={400}
                  center={portalSetting.mapConfiguration?.centre}
                  region={portalSetting.mapConfiguration?.region}
                  language={portalSetting.defaultLanguage.toLowerCase()}
                  zoom={portalSetting.mapConfiguration?.zoomLevel}
                  extraButton={
                    <Button variant="contained" sx={{ marginBottom: '20px' }}>
                      {formatMessage({
                        id: 'portal_item_edit.button.confirm_location',
                      })}
                    </Button>
                  }
                  onExtraButtonClick={handleConfirmLocation}
                  formName={FORM_NAME}
                />
              )}
            {errors.details?.[index]?.texts?.[portalSetting.defaultLanguage]
              ?.type === 'validate' && (
              <FormHelperText>
                {formatMessage({
                  id:
                    details?.[index].type === ContactDetailType.PHONE
                      ? 'general.error.validate_phone'
                      : 'general.error.validate_email',
                })}
              </FormHelperText>
            )}
            {errors.details?.[index]?.texts?.[portalSetting.defaultLanguage]
              ?.type === 'maxLength' && (
              <FormHelperText>
                {formatMessage(
                  {
                    id: 'general.error.max_length',
                  },
                  { max: EMAIL_MAX_LENGTH },
                )}
              </FormHelperText>
            )}
          </FormControl>
        </Stack>
      ))}

      <Box width="100%">
        <Button
          size="small"
          startIcon={<AddIcon />}
          onClick={(): void => {
            void handleAddDetail()
          }}
        >
          {formatMessage({
            id: 'contact_edit_dialog.button.add_contact',
          })}
        </Button>
      </Box>

      <Stack width="100%">
        <Button
          fullWidth
          onClick={handleSave}
          variant="contained"
          disabled={shouldDisableSaving}
        >
          {formatMessage({ id: 'general.button.save' })}
        </Button>
        <Button fullWidth onClick={onClose}>
          {formatMessage({ id: 'general.button.cancel' })}
        </Button>
      </Stack>

      <TranslationDialog
        isOpen={isTranslationDialogOpen}
        title={translationDialogTitle}
        rows={translationInputRows}
        defaultValue={getTranslationDefaultLanguageValue()}
        maxLength={maxTranslationLength}
        onSave={handleSaveTranslation}
        onClose={handleCloseTranslationDialog}
      />
    </Wrapper>
  )
}

export default ContactEditForm
