import { useCallback, useState, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useSnackbar } from 'notistack'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import useSWR from 'swr'
import { useForm, type SubmitHandler, Controller } from 'react-hook-form'
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'
import styled from '@mui/material/styles/styled'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import InputAdornment from '@mui/material/InputAdornment'
import PersonIcon from '@mui/icons-material/Person'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import LoadingButton from '@mui/lab/LoadingButton'
import Box from '@mui/material/Box'
import ImageUploader from 'components/form/ImageUploader'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import EmailIcon from '@mui/icons-material/Email'
import PhoneIcon from '@mui/icons-material/Phone'
import WorkIcon from '@mui/icons-material/Work'
import EditIcon from '@mui/icons-material/Edit'
import CloseIcon from '@mui/icons-material/Close'

import {
  BackButtonGrey,
  LightSubHeader,
  MainHeader,
  SettingWrapper,
} from 'components/StyledComponents'
import useRoute from 'hooks/useNavigate'
import {
  hasUnsavedChangesState,
  isFileUploadingState,
  processedFormFilesState,
} from 'state/formStates'
import { portalSettingState } from 'state/portalSettingStates'
import { type Member, type TeamBasic } from 'types'
import {
  EMAIL_MAX_LENGTH,
  Path,
  PHONE_MAX_LENGTH,
  SHORT_TEXT_MAX_LENGTH,
  USER_NAME_MAX_LENGTH,
} from '../commonConstants'
import useApi from 'hooks/useApi'
import { isValidEmail, isValidPhoneNumber } from 'utils/stringUtils'
import usePortalSetting from 'hooks/usePortalSetting'
import { type RoleBasic } from 'components/role/roleTypes'
import { type ItemBasic } from 'components/item/itemTypes'

type MemberFormFields = {
  firstName: string
  lastName: string
  email: string
  phone?: string
  jobTitle?: string
  avatarUrl?: string
}

const FORM_NAME = 'editMemberForm'

const MAX_IMAGE_WIDTH_IN_PX_FOR_UPLOAD = 300
const MAX_IMAGE_HEIGHT_IN_PX_FOR_UPLOAD = 300

export const ContentWrapper = styled(Stack)`
  background: ${({ theme }) => theme.palette.info.light};
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  width: 100%;
  gap: ${({ theme }) => theme.spacing(1)};
  padding: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(2)};
  margin-top: ${({ theme }) => theme.spacing(1)};
  align-items: center;
`

const MemberEditPage: React.FC = () => {
  const { formatMessage } = useIntl()
  const { goTo } = useRoute()
  const { memberId } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const [isSaving, setIsSaving] = useState(false)
  const [isSending, setIsSending] = useState(false)
  const setHasUnsavedChanges = useSetRecoilState(hasUnsavedChangesState)
  const portalSetting = useRecoilValue(portalSettingState)
  const { sendPutRequest, sendPostRequest } = useApi()
  const processedFormFiles = useRecoilValue(processedFormFilesState(FORM_NAME))
  const isFileUploading = useRecoilValue(isFileUploadingState(FORM_NAME))
  const [isRolesDialogOpen, setIsRolesDialogOpen] = useState(false)
  const [isItemsDialogOpen, setIsItemsDialogOpen] = useState(false)
  const [isTeamsDialogOpen, setIsTeamsDialogOpen] = useState(false)
  const [rolesData, setRolesData] = useState<RoleBasic[]>([])
  const [selectedRoleIds, setSelectedRoleIds] = useState<string[]>([])
  const [itemsData, setItemsData] = useState<ItemBasic[]>([])
  const [teamsData, setTeamsData] = useState<TeamBasic[]>([])
  const { getLocalizedContent, formatPhoneNumber } = usePortalSetting()
  const [selectedItemIds, setSelectedItemIds] = useState<string[]>([])
  const [selectedTeamIds, setSelectedTeamIds] = useState<string[]>([])

  const { data: memberData, mutate: mutateMemberData } = useSWR<Member>(
    portalSetting && memberId
      ? `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting.id
        }/members/${memberId}`
      : null,
  )
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isDirty, isValid },
  } = useForm<MemberFormFields>({
    mode: 'onTouched',
  })

  useEffect(() => {
    if (memberData) {
      setValue('firstName', memberData.firstName)
      setValue('lastName', memberData.lastName)
      setValue('email', memberData.email)
      setValue('phone', memberData.phone)
      setValue('jobTitle', memberData.jobTitle)
    }
  }, [memberData])

  useEffect(() => {
    if (memberData && teamsData.length > 0) {
      const validTeamsIds = teamsData.map((team) => team.id)

      setSelectedTeamIds(
        memberData.teams
          .map((team) => team.id)
          .filter((teamId) => validTeamsIds.includes(teamId)),
      )
    }
  }, [memberData, teamsData])

  useEffect(() => {
    if (memberData && itemsData.length > 0) {
      const validItemsIds = itemsData.map((item) => item.id)

      setSelectedItemIds(
        memberData.items
          .map((item) => item.id)
          .filter((itemId) => validItemsIds.includes(itemId)),
      )
    }
  }, [memberData, itemsData])

  useEffect(() => {
    if (memberData && rolesData.length > 0) {
      const validRolesIds = rolesData.map((role) => role.id)
      setSelectedRoleIds(
        memberData.roles
          .map((role) => role.id)
          .filter((roleId) => validRolesIds.includes(roleId)),
      )
    }
  }, [memberData, rolesData])

  useEffect(() => {
    if (processedFormFiles.length > 0) {
      setValue('avatarUrl', processedFormFiles[0].url)
    }
  }, [processedFormFiles])

  const onSubmit: SubmitHandler<MemberFormFields> = useCallback(
    async (data): Promise<void> => {
      if (portalSetting && memberId) {
        try {
          setIsSaving(true)
          const formData = {
            ...data,
            accessPortal: true,
          }

          if (data.phone) {
            formData.phone = formatPhoneNumber(data.phone)
          }

          await sendPutRequest(
            `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
              portalSetting.id
            }/members/${memberId}`,
            formData,
          )

          enqueueSnackbar(
            formatMessage({
              id: 'general.text.changes_saved',
            }),
            {
              variant: 'success',
            },
          )
        } catch (error) {
          console.error(error)
        } finally {
          setIsSaving(false)
          setHasUnsavedChanges(false)
        }
      }
    },
    [portalSetting, memberId, processedFormFiles],
  )

  const handleGoBack = (): void => {
    goTo(Path.MEMBERS_LIST)
  }

  const hasUnsavedChanges = isDirty

  const shouldDisableSaving = useMemo(
    () => !isValid || isFileUploading || isSaving,
    [isValid, isSaving, isFileUploading],
  )

  const getRoles = useCallback(async (): Promise<void> => {
    try {
      if (portalSetting) {
        const response = await sendPostRequest(
          `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
            portalSetting.id
          }/members:listAssignableRoles`,
        )
        const data = await response.json()
        setRolesData(data)
      }
    } catch (error) {
      console.error(error)
    }
  }, [portalSetting])

  const getItems = useCallback(async (): Promise<void> => {
    try {
      if (portalSetting) {
        const response = await sendPostRequest(
          `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
            portalSetting.id
          }/members:listAssignableItems`,
        )
        const data = await response.json()
        setItemsData(data)
      }
    } catch (error) {
      console.error(error)
    }
  }, [portalSetting])

  const getTeams = useCallback(async (): Promise<void> => {
    try {
      if (portalSetting) {
        const response = await sendPostRequest(
          `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
            portalSetting.id
          }/members:listAssignableTeams`,
        )
        const data = await response.json()
        setTeamsData(data)
      }
    } catch (error) {
      console.error(error)
    }
  }, [portalSetting])

  useEffect(() => {
    setHasUnsavedChanges(hasUnsavedChanges)
  }, [hasUnsavedChanges])

  useEffect(() => {
    if (portalSetting) {
      void getRoles()
      void getItems()
      void getTeams()
    }
  }, [portalSetting])

  const sendResetPasswordEmail = useCallback(async (): Promise<void> => {
    if (memberData?.email) {
      try {
        setIsSending(true)
        const auth = getAuth()
        await sendPasswordResetEmail(auth, memberData.email)
        enqueueSnackbar(
          formatMessage({ id: 'member_edit.text.reset_email_sent' }),
          {
            variant: 'success',
          },
        )
      } catch (error) {
        enqueueSnackbar(formatMessage({ id: 'general.error.unknown_error' }), {
          variant: 'error',
        })
      } finally {
        setIsSending(false)
      }
    }
  }, [memberData?.email])

  const handleEditRoles = (): void => {
    setIsRolesDialogOpen(true)
  }

  const handleCloseRolesDialog = (): void => {
    setIsRolesDialogOpen(false)
  }

  const handleEditItems = (): void => {
    setIsItemsDialogOpen(true)
  }

  const handleCloseItemsDialog = (): void => {
    setIsItemsDialogOpen(false)
  }

  const handleEditTeams = (): void => {
    setIsTeamsDialogOpen(true)
  }

  const handleCloseTeamsDialog = (): void => {
    setIsTeamsDialogOpen(false)
  }

  const handleSaveRoles = useCallback(async (): Promise<void> => {
    if (portalSetting && memberId && selectedRoleIds.length > 0 && memberData) {
      try {
        setIsSaving(true)

        const response = await sendPutRequest(
          `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
            portalSetting.id
          }/members/${memberId}/roles`,
          {
            identifiers: selectedRoleIds,
          },
        )

        const rolesData = await response.json()

        await mutateMemberData({
          ...memberData,
          roles: rolesData as RoleBasic[],
        })

        enqueueSnackbar(
          formatMessage({
            id: 'general.text.changes_saved',
          }),
          {
            variant: 'success',
          },
        )
        handleCloseRolesDialog()
      } catch (error) {
        console.error(error)
      } finally {
        setIsSaving(false)
      }
    }
  }, [portalSetting, memberId, selectedRoleIds, memberData])

  const handleSaveItems = useCallback(async (): Promise<void> => {
    if (portalSetting && memberId && memberData) {
      try {
        setIsSaving(true)

        const response = await sendPutRequest(
          `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
            portalSetting.id
          }/members/${memberId}/items`,
          {
            identifiers: selectedItemIds,
          },
        )

        const itemsData = await response.json()
        console.log(itemsData)
        await mutateMemberData({
          ...memberData,
          items: itemsData as ItemBasic[],
        })

        enqueueSnackbar(
          formatMessage({
            id: 'general.text.changes_saved',
          }),
          {
            variant: 'success',
          },
        )
        handleCloseItemsDialog()
      } catch (error) {
        console.error(error)
      } finally {
        setIsSaving(false)
      }
    }
  }, [portalSetting, memberId, selectedItemIds, memberData])

  const handleSaveTeams = useCallback(async (): Promise<void> => {
    if (portalSetting && memberId && memberData) {
      try {
        setIsSaving(true)

        const response = await sendPutRequest(
          `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
            portalSetting.id
          }/members/${memberId}/teams`,
          {
            identifiers: selectedTeamIds,
          },
        )

        const teamsData = await response.json()
        console.log(teamsData)
        await mutateMemberData({
          ...memberData,
          teams: teamsData as TeamBasic[],
        })

        enqueueSnackbar(
          formatMessage({
            id: 'general.text.changes_saved',
          }),
          {
            variant: 'success',
          },
        )
        handleCloseTeamsDialog()
      } catch (error) {
        console.error(error)
      } finally {
        setIsSaving(false)
      }
    }
  }, [portalSetting, memberId, selectedTeamIds, memberData])

  const handleRolesChange = (checked: boolean, roleId: string): void => {
    if (checked) {
      setSelectedRoleIds((prev) => [...prev, roleId])
    } else {
      setSelectedRoleIds((prev) => prev.filter((id) => id !== roleId))
    }
  }

  const handleItemsChange = (checked: boolean, itemId: string): void => {
    if (checked) {
      setSelectedItemIds((prev) => [...prev, itemId])
    } else {
      setSelectedItemIds((prev) => prev.filter((id) => id !== itemId))
    }
  }

  const handleTeamsChange = (checked: boolean, teamId: string): void => {
    if (checked) {
      setSelectedTeamIds((prev) => [...prev, teamId])
    } else {
      setSelectedTeamIds((prev) => prev.filter((id) => id !== teamId))
    }
  }

  if (!portalSetting || !memberId) {
    return null
  }

  return (
    <Stack height={'100%'} width={'100%'}>
      <Stack direction="row" width="100%" spacing={1} marginBottom={2}>
        <BackButtonGrey
          onClick={handleGoBack}
          size="small"
          aria-label={formatMessage({
            id: 'general.icon_button.go_back',
          })}
        >
          <ArrowBackIcon />
        </BackButtonGrey>

        <MainHeader>
          {formatMessage({
            id: 'member_edit.header',
          })}
        </MainHeader>
      </Stack>

      <SettingWrapper width="100%" flexGrow={1}>
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack paddingY={2} paddingX={4} spacing={2}>
            <Stack direction="row" width="100%">
              <Stack flexGrow={1}>
                <LightSubHeader>
                  {formatMessage({
                    id: 'member_edit.subheader.personal_information',
                  })}
                </LightSubHeader>
              </Stack>

              <LoadingButton
                variant="contained"
                size="small"
                type="submit"
                disabled={shouldDisableSaving}
                loading={isSaving}
                color="secondary"
              >
                {formatMessage({ id: 'general.button.save' })}
              </LoadingButton>
            </Stack>

            <Stack direction="row" spacing={2} width="100%">
              <Box width="220px" height="220px">
                <ImageUploader
                  formName={FORM_NAME}
                  maxImageWidth={MAX_IMAGE_WIDTH_IN_PX_FOR_UPLOAD}
                  maxImageHeight={MAX_IMAGE_HEIGHT_IN_PX_FOR_UPLOAD}
                  defaultImageUrl={memberData?.avatarUrl}
                />
              </Box>

              <Stack spacing={2} flexGrow={1}>
                <Stack direction="row" width="100%" spacing={2}>
                  <FormControl error={!!errors.firstName} fullWidth>
                    <Controller
                      name="firstName"
                      control={control}
                      rules={{
                        required: true,
                        maxLength: USER_NAME_MAX_LENGTH,
                      }}
                      defaultValue={''}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          error={!!errors.firstName}
                          required
                          size="small"
                          label={formatMessage({
                            id: 'member_edit.label.first_name',
                          })}
                          slotProps={{
                            input: {
                              startAdornment: (
                                <InputAdornment position="start">
                                  <PersonIcon
                                    fontSize="small"
                                    color="primary"
                                  />
                                </InputAdornment>
                              ),
                            },
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                  </FormControl>

                  <FormControl error={!!errors.lastName} fullWidth>
                    <Controller
                      name="lastName"
                      control={control}
                      rules={{
                        required: true,
                        maxLength: USER_NAME_MAX_LENGTH,
                      }}
                      defaultValue={''}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          error={!!errors.lastName}
                          required
                          size="small"
                          label={formatMessage({
                            id: 'customer_edit.label.last_name',
                          })}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                  </FormControl>
                </Stack>

                <FormControl error={!!errors.email}>
                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      maxLength: EMAIL_MAX_LENGTH,
                      required: true,
                      validate: (val): boolean => {
                        if (!val) {
                          return true
                        }

                        return isValidEmail(val)
                      },
                    }}
                    defaultValue={''}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        required
                        disabled={!!memberData}
                        size="small"
                        label={formatMessage({
                          id: 'member_edit.label.email',
                        })}
                        slotProps={{
                          input: {
                            startAdornment: (
                              <InputAdornment position="start">
                                <EmailIcon fontSize="small" color="primary" />
                              </InputAdornment>
                            ),
                          },
                        }}
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />

                  {errors.email?.type === 'validate' && (
                    <FormHelperText>
                      {formatMessage({
                        id: 'general.error.validate_email',
                      })}
                    </FormHelperText>
                  )}
                  {errors.email?.type === 'maxLength' && (
                    <FormHelperText>
                      {formatMessage(
                        {
                          id: 'general.error.max_length_email',
                        },
                        { max: EMAIL_MAX_LENGTH },
                      )}
                    </FormHelperText>
                  )}
                  {errors.email?.type === 'required' && (
                    <FormHelperText>
                      {formatMessage({
                        id: 'general.error.required',
                      })}
                    </FormHelperText>
                  )}
                </FormControl>

                <FormControl error={!!errors.phone}>
                  <Controller
                    name="phone"
                    control={control}
                    rules={{
                      maxLength: PHONE_MAX_LENGTH,
                      validate: (val): boolean => {
                        if (!val) {
                          return true
                        }

                        return isValidPhoneNumber(val)
                      },
                    }}
                    defaultValue={''}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        label={formatMessage({
                          id: 'member_edit.label.phone',
                        })}
                        slotProps={{
                          input: {
                            startAdornment: (
                              <InputAdornment position="start">
                                <PhoneIcon fontSize="small" color="primary" />
                              </InputAdornment>
                            ),
                          },
                        }}
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />

                  {errors.phone?.type === 'validate' && (
                    <FormHelperText>
                      {formatMessage({
                        id: 'general.error.validate_phone',
                      })}
                    </FormHelperText>
                  )}
                  {errors.phone?.type === 'maxLength' && (
                    <FormHelperText>
                      {formatMessage(
                        {
                          id: 'general.error.max_length_phone',
                        },
                        { max: PHONE_MAX_LENGTH },
                      )}
                    </FormHelperText>
                  )}
                </FormControl>

                <FormControl error={!!errors.jobTitle}>
                  <Controller
                    name="jobTitle"
                    control={control}
                    rules={{
                      maxLength: SHORT_TEXT_MAX_LENGTH,
                    }}
                    defaultValue={''}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        label={formatMessage({
                          id: 'member_edit.label.job_title',
                        })}
                        slotProps={{
                          input: {
                            startAdornment: (
                              <InputAdornment position="start">
                                <WorkIcon fontSize="small" color="primary" />
                              </InputAdornment>
                            ),
                          },
                        }}
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />

                  {errors.jobTitle?.type === 'maxLength' && (
                    <FormHelperText>
                      {formatMessage(
                        {
                          id: 'general.error.max_length',
                        },
                        { max: SHORT_TEXT_MAX_LENGTH },
                      )}
                    </FormHelperText>
                  )}
                </FormControl>

                {memberId && (
                  <LoadingButton
                    variant="text"
                    size="small"
                    disabled={isSending}
                    loading={isSending}
                    onClick={() => {
                      void sendResetPasswordEmail()
                    }}
                  >
                    {formatMessage({
                      id: 'member_edit.button.send_reset_password_email',
                    })}
                  </LoadingButton>
                )}
              </Stack>
            </Stack>
          </Stack>
        </form>

        <Divider />

        <Stack paddingY={2} paddingX={4} spacing={2}>
          <LightSubHeader>
            {formatMessage({
              id: 'member_edit.subheader.access_preferences',
            })}
          </LightSubHeader>

          <ContentWrapper direction="row" data-testid="roles-list">
            <Box flexGrow={1}>
              <Typography variant="body1">
                {formatMessage({
                  id: 'member_edit.label.roles',
                })}
              </Typography>
            </Box>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="body2">
                {(memberData?.roles ?? []).map((role) => role.name).join(', ')}
              </Typography>

              <IconButton
                size="small"
                onClick={handleEditRoles}
                aria-label={formatMessage({
                  id: 'general.icon_button.edit',
                })}
                color="primary"
              >
                <EditIcon />
              </IconButton>
            </Stack>
          </ContentWrapper>

          <ContentWrapper direction="row" data-testid="items-list">
            <Box flexGrow={1}>
              <Typography variant="body1">
                {formatMessage({
                  id: 'member_edit.label.items',
                })}
              </Typography>
            </Box>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="body2">
                {(memberData?.items ?? [])
                  .map((item) => getLocalizedContent(item.names))
                  .join(', ')}
              </Typography>

              <IconButton
                size="small"
                onClick={handleEditItems}
                aria-label={formatMessage({
                  id: 'general.icon_button.edit',
                })}
                color="primary"
              >
                <EditIcon />
              </IconButton>
            </Stack>
          </ContentWrapper>

          <ContentWrapper direction="row" data-testid="teams-list">
            <Box flexGrow={1}>
              <Typography variant="body1">
                {formatMessage({
                  id: 'member_edit.label.teams',
                })}
              </Typography>
            </Box>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="body2">
                {(memberData?.teams ?? []).map((team) => team.name).join(', ')}
              </Typography>

              <IconButton
                size="small"
                onClick={handleEditTeams}
                aria-label={formatMessage({
                  id: 'general.icon_button.edit',
                })}
                color="primary"
              >
                <EditIcon />
              </IconButton>
            </Stack>
          </ContentWrapper>
        </Stack>
      </SettingWrapper>

      <Dialog open={isRolesDialogOpen} onClose={handleCloseRolesDialog}>
        <DialogTitle>
          {formatMessage({
            id: 'member_edit.dialog.roles.title',
          })}
        </DialogTitle>
        <IconButton
          onClick={handleCloseRolesDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          aria-label={formatMessage({
            id: 'general.button.close',
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Stack width="500px" spacing={1}>
            {rolesData?.map((roleData) => (
              <FormControlLabel
                key={roleData.id}
                control={
                  <Checkbox
                    checked={selectedRoleIds.includes(roleData.id)}
                    onChange={(event, checked) => {
                      handleRolesChange(checked, roleData.id)
                    }}
                  />
                }
                label={roleData.name}
              />
            ))}
          </Stack>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            fullWidth
            onClick={(): void => {
              void handleSaveRoles()
            }}
            variant="contained"
            loading={isSaving}
            disabled={selectedRoleIds.length === 0}
          >
            {formatMessage({ id: 'general.button.save' })}
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <Dialog open={isItemsDialogOpen} onClose={handleCloseItemsDialog}>
        <DialogTitle>
          {formatMessage({
            id: 'member_edit.dialog.items.title',
          })}
        </DialogTitle>
        <IconButton
          onClick={handleCloseItemsDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          aria-label={formatMessage({
            id: 'general.button.close',
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Stack width="500px" spacing={1}>
            {itemsData?.map((itemData) => (
              <FormControlLabel
                key={itemData.id}
                control={
                  <Checkbox
                    checked={selectedItemIds.includes(itemData.id)}
                    onChange={(event, checked) => {
                      handleItemsChange(checked, itemData.id)
                    }}
                  />
                }
                label={getLocalizedContent(itemData.names)}
              />
            ))}
          </Stack>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            fullWidth
            onClick={(): void => {
              void handleSaveItems()
            }}
            variant="contained"
            loading={isSaving}
          >
            {formatMessage({ id: 'general.button.save' })}
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <Dialog open={isTeamsDialogOpen} onClose={handleCloseTeamsDialog}>
        <DialogTitle>
          {formatMessage({
            id: 'member_edit.dialog.teams.title',
          })}
        </DialogTitle>
        <IconButton
          onClick={handleCloseTeamsDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          aria-label={formatMessage({
            id: 'general.button.close',
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Stack width="500px" spacing={1}>
            {teamsData?.map((teamData) => (
              <FormControlLabel
                key={teamData.id}
                control={
                  <Checkbox
                    checked={selectedTeamIds.includes(teamData.id)}
                    onChange={(event, checked) => {
                      handleTeamsChange(checked, teamData.id)
                    }}
                  />
                }
                label={teamData.name}
              />
            ))}
          </Stack>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            fullWidth
            onClick={(): void => {
              void handleSaveTeams()
            }}
            variant="contained"
            loading={isSaving}
          >
            {formatMessage({ id: 'general.button.save' })}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Stack>
  )
}

export default MemberEditPage
