import { useCallback, useState, useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useSnackbar } from 'notistack'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { useForm, type SubmitHandler, Controller } from 'react-hook-form'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import OutlinedInput from '@mui/material/OutlinedInput'
import MenuItem from '@mui/material/MenuItem'
import Checkbox from '@mui/material/Checkbox'
import ListItemText from '@mui/material/ListItemText'
import FormHelperText from '@mui/material/FormHelperText'
import Chip from '@mui/material/Chip'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import LoadingButton from '@mui/lab/LoadingButton'
import Divider from '@mui/material/Divider'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CottageIcon from '@mui/icons-material/Cottage'
import PersonIcon from '@mui/icons-material/Person'

import {
  BackButtonGrey,
  LightSubHeader,
  MainHeader,
  SettingWrapper,
} from 'components/StyledComponents'
import useRoute from 'hooks/useNavigate'
import { hasUnsavedChangesState } from 'state/formStates'
import { portalSettingState } from 'state/portalSettingStates'
import {
  CUSTOMER_PROFILE_FORM_FIELDS,
  CustomerProfileInputName,
  CustomerProfileInputType,
  Path,
  USER_NAME_MAX_LENGTH,
} from '../commonConstants'
import useApi from 'hooks/useApi'
import usePortalSetting from 'hooks/usePortalSetting'
import { type ItemBasic } from 'components/item/itemTypes'

type CustomerFormData = {
  itemIds: string[]
} & {
  [K in CustomerProfileInputName[number]]?: string
}
const CustomerAddPage: React.FC = () => {
  const { formatMessage } = useIntl()
  const { goTo } = useRoute()
  const { enqueueSnackbar } = useSnackbar()
  const [isSaving, setIsSaving] = useState(false)
  const setHasUnsavedChanges = useSetRecoilState(hasUnsavedChangesState)
  const portalSetting = useRecoilValue(portalSettingState)
  const { getLocalizedContent } = usePortalSetting()
  const { sendPostRequest } = useApi()
  const [itemsData, setItemsData] = useState<ItemBasic[]>([])
  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    getValues,
  } = useForm<CustomerFormData>({
    mode: 'onTouched',
  })

  const onSubmit: SubmitHandler<CustomerFormData> = useCallback(
    async (data): Promise<void> => {
      if (portalSetting) {
        try {
          setIsSaving(true)

          await sendPostRequest(
            `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
              portalSetting.id
            }/customers`,
            data,
          )

          enqueueSnackbar(formatMessage({ id: 'general.text.changes_saved' }), {
            variant: 'success',
          })

          goTo(Path.CUSTOMERS_LIST, true)
        } catch (error) {
          console.error(error)
        } finally {
          setIsSaving(false)
          setHasUnsavedChanges(false)
        }
      }
    },
    [portalSetting],
  )

  const getItems = useCallback(async (): Promise<void> => {
    try {
      if (portalSetting) {
        const response = await sendPostRequest(
          `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
            portalSetting.id
          }/members:listAssignableItems`,
        )
        const data = await response.json()
        setItemsData(data)
      }
    } catch (error) {
      console.error(error)
    }
  }, [portalSetting])

  useEffect(() => {
    if (portalSetting) {
      void getItems()
    }
  }, [portalSetting])

  const handleGoBack = (): void => {
    goTo(Path.CUSTOMERS_LIST)
  }

  const hasUnsavedChanges = isDirty

  const shouldDisableSaving = useMemo(
    () => !isValid || isSaving,
    [isValid, isSaving],
  )

  useEffect(() => {
    setHasUnsavedChanges(hasUnsavedChanges)
  }, [hasUnsavedChanges])

  const renderCustomerProfileFormFields = useCallback(() => {
    if (portalSetting) {
      return CUSTOMER_PROFILE_FORM_FIELDS.map(
        ({ name, label, maxLength, validate, icon }) => {
          if (
            portalSetting.portalInfo?.customerProfile[name] ===
            CustomerProfileInputType.HIDDEN
          ) {
            return null
          }

          const IconComponent = icon

          console.log(name)

          return (
            <FormControl key={name} error={!!errors[name]} fullWidth>
              <Controller
                name={name}
                control={control}
                rules={{
                  required:
                    portalSetting.portalInfo?.customerProfile[name] ===
                    CustomerProfileInputType.REQUIRED,
                  maxLength,
                  validate: (val): boolean => {
                    if (validate) {
                      return validate(val)
                    }

                    return true
                  },
                }}
                defaultValue={''}
                render={({ field }) => (
                  <TextField
                    {...field}
                    required={
                      portalSetting?.portalInfo?.customerProfile[name] ===
                      CustomerProfileInputType.REQUIRED
                    }
                    error={!!errors[name]}
                    size="small"
                    label={formatMessage({
                      id: label,
                    })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconComponent fontSize="small" color="primary" />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    fullWidth
                  />
                )}
              />

              {name === CustomerProfileInputName.PHONE &&
                errors?.[name]?.type === 'validate' && (
                  <FormHelperText>
                    {formatMessage({
                      id: 'general.error.validate_phone',
                    })}
                  </FormHelperText>
                )}

              {name === CustomerProfileInputName.EMAIL &&
                errors?.[name]?.type === 'validate' && (
                  <FormHelperText>
                    {formatMessage({
                      id: 'general.error.validate_email',
                    })}
                  </FormHelperText>
                )}

              {errors?.[name]?.type === 'required' && (
                <FormHelperText>
                  {formatMessage({
                    id: 'general.error.required',
                  })}
                </FormHelperText>
              )}

              {errors?.[name]?.type === 'maxLength' && (
                <FormHelperText>
                  {formatMessage(
                    {
                      id: 'general.error.max_length',
                    },
                    {
                      max: maxLength,
                    },
                  )}
                </FormHelperText>
              )}
            </FormControl>
          )
        },
      )
    }

    return null
  }, [portalSetting, errors])

  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
        <Stack height={'100%'} width={'100%'}>
          <Stack direction="row" width="100%" spacing={2} marginBottom={2}>
            <Stack flexGrow={1} direction="row" spacing={1}>
              <BackButtonGrey
                onClick={handleGoBack}
                size="small"
                aria-label={formatMessage({
                  id: 'general.icon_button.go_back',
                })}
              >
                <ArrowBackIcon />
              </BackButtonGrey>

              <MainHeader>
                {formatMessage({
                  id: 'customer_add.header',
                })}
              </MainHeader>
            </Stack>

            <LoadingButton
              variant="contained"
              size="small"
              type="submit"
              disabled={shouldDisableSaving}
              loading={isSaving}
              color="secondary"
            >
              {formatMessage({ id: 'general.button.save' })}
            </LoadingButton>
          </Stack>

          <SettingWrapper width="100%" flexGrow={1}>
            <Stack paddingY={2} paddingX={4} spacing={2}>
              <LightSubHeader>
                {formatMessage({
                  id: 'customer_edit.subheader.personal_information',
                })}
              </LightSubHeader>

              <Stack spacing={2} flexGrow={1}>
                {portalSetting?.portalInfo?.customerProfile.name !==
                  CustomerProfileInputType.HIDDEN && (
                  <Stack direction="row" width="100%" spacing={2}>
                    <FormControl error={!!errors.firstName} fullWidth>
                      <Controller
                        name="firstName"
                        control={control}
                        rules={{
                          required:
                            portalSetting?.portalInfo?.customerProfile.name ===
                            CustomerProfileInputType.REQUIRED,
                          maxLength: USER_NAME_MAX_LENGTH,
                        }}
                        defaultValue={''}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            error={!!errors.firstName}
                            required={
                              portalSetting?.portalInfo?.customerProfile
                                .name === CustomerProfileInputType.REQUIRED
                            }
                            size="small"
                            label={formatMessage({
                              id: 'customer_edit.label.first_name',
                            })}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <PersonIcon
                                    fontSize="small"
                                    color="primary"
                                  />
                                </InputAdornment>
                              ),
                            }}
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />
                    </FormControl>

                    <FormControl error={!!errors.lastName} fullWidth>
                      <Controller
                        name="lastName"
                        control={control}
                        rules={{
                          required:
                            portalSetting?.portalInfo?.customerProfile.name ===
                            CustomerProfileInputType.REQUIRED,
                          maxLength: USER_NAME_MAX_LENGTH,
                        }}
                        defaultValue={''}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            error={!!errors.lastName}
                            required={
                              portalSetting?.portalInfo?.customerProfile
                                .name === CustomerProfileInputType.REQUIRED
                            }
                            size="small"
                            label={formatMessage({
                              id: 'customer_edit.label.last_name',
                            })}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />
                    </FormControl>
                  </Stack>
                )}

                {renderCustomerProfileFormFields()}
              </Stack>
            </Stack>

            <Divider />

            <Stack paddingY={2} paddingX={4} spacing={2}>
              <LightSubHeader>
                {formatMessage({
                  id: 'member_edit.subheader.access_preferences',
                })}
              </LightSubHeader>

              <FormControl error={!!errors.itemIds}>
                <InputLabel id="customer-item-label" size="small" required>
                  {formatMessage({
                    id: 'customer_edit.label.item',
                  })}
                </InputLabel>
                <Controller
                  name="itemIds"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  defaultValue={[]}
                  render={({ field }) => (
                    <Select
                      {...field}
                      required
                      multiple
                      labelId="customer-item-label"
                      label={formatMessage({
                        id: 'customer_edit.label.item',
                      })}
                      startAdornment={
                        <InputAdornment position="start">
                          <CottageIcon fontSize="small" color="primary" />
                        </InputAdornment>
                      }
                      input={
                        <OutlinedInput
                          label={formatMessage({
                            id: 'customer_edit.label.item',
                          })}
                        />
                      }
                      size="small"
                      fullWidth
                      variant="outlined"
                      renderValue={(selected) => (
                        <Box
                          sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: 0.5,
                          }}
                        >
                          {selected.map((value) => {
                            const selectedItem = itemsData?.find(
                              (item) => item.id === value,
                            )

                            return (
                              <Chip
                                key={value}
                                size="small"
                                label={getLocalizedContent(selectedItem?.names)}
                              />
                            )
                          })}
                        </Box>
                      )}
                    >
                      {itemsData?.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          <Checkbox
                            checked={getValues('itemIds')?.includes(item.id)}
                          />
                          <ListItemText
                            primary={getLocalizedContent(item.names)}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Stack>
          </SettingWrapper>
        </Stack>
      </form>
    </>
  )
}

export default CustomerAddPage
